<template>
  <v-app>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4> Maintenance mode</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Maintenance mode
                </div>
              </div>

            </div>
          </div>
          <div class="card-body">
            <div class="row">

              <div class="col-6">
                <label for="">Maintenance mode</label>
                <v-switch
                    v-model="setting.is_maintenance_mode"
                    :label="setting.is_maintenance_mode ? 'On' : 'off'"
                    color="primary">
                </v-switch>

              </div>

            </div>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                class="text-white ml-2 btn btn-primary"
                depressed :loading="loading"
                dark
                @click="updateSetting()">
              Save
            </v-btn>
          </v-card-actions>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script >
import SiteSettingService from "@/core/services/site-setting/SiteSettingService";
const siteSetting=new SiteSettingService();
export default {
  props:['setting'],
  data(){
    return{
      loading:false,
    }
  },
  methods:{
    updateSetting(){
      this.loading = true;

      siteSetting.update(this.setting.id, this.setting)
          .then(response => {
            this.$snotify.success("Information updated");
            this.$emit("refresh");
            this.getSetting();
          })
          .catch(error => {
            console.log(error)
          })
          .finally(this.loading = false);
    }
  }

}
</script>