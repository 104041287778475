<template>
    <v-app>
        <v-card flat>
            <v-card-text>
                <div class="row">
                    <v-col class="float-right text-right">
                        <v-btn  outlined color="red" @click="resetColors()">Reset theme colors</v-btn>
                    </v-col>
                    <v-tabs vertical class="justify-start">

                        <v-tab class="justify-start">
                            Login screen
                        </v-tab>
                        <v-tab class="justify-start">
                            App sidebar
                        </v-tab>
                        <v-tab class="justify-start">
                            App header
                        </v-tab>
                        <v-tab class="justify-start">
                            Button color
                        </v-tab>
                        <v-tab class="justify-start">
                            Email Template
                        </v-tab>

                        <v-tab-item>
                            <v-card flat>
                                <v-card-text>
                                    <div class="row pa-6">
                                        <div class="col-12 form-group">
                                            <label for>Background image</label>
                                            <img
                                                    v-if="setting.login_bg_path && setting.login_bg_path['thumb']"
                                                    style="height:100px"
                                                    :src="setting.login_bg_path['thumb']"
                                                    alt
                                                    class="mt-5"
                                            />
                                            <b-form-file
                                                    v-model="setting.login_bg_image"
                                                    placeholder="Choose a file or drop it here..."
                                                    drop-placeholder="Drop file here..."
                                            ></b-form-file>
                                        </div>
                                        <div class="col-4 mt-2 form-group">
                                            <label for>Background color</label><br/>
                                            <verte
                                                    menuPosition="bottom"
                                                    model="hex"
                                                    v-model="colors_variables.login.bg_color"
                                            ></verte>

                                        </div>
                                        <div class="col-4 form-group">
                                            <label for>College name color</label><br/>
                                            <verte
                                                    @input="setStyleVariable"
                                                    menuPosition="bottom"
                                                    model="hex"
                                                    v-model="colors_variables.login.primary_color"
                                            ></verte>

                                        </div>

                                        <div class="col-4 form-group">
                                            <label for>Slogan color</label><br/>
                                            <verte
                                                    @input="setStyleVariable"
                                                    menuPosition="bottom"
                                                    model="hex"
                                                    v-model="colors_variables.login.secondary_color"
                                            ></verte>

                                        </div>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card flat>
                                <v-card-text>

                                    <div class="row">
                                        <div class="col-6 form-group">
                                            <label for>Background color</label><br/>
                                            <verte
                                                    @input="setStyleVariable"
                                                    menuPosition="bottom"
                                                    model="hex"
                                                    v-model="colors_variables.side_bar.primary_color"
                                            ></verte>

                                        </div>
                                        <div class="col-6 form-group">
                                            <label for>Text color</label><br/>
                                            <verte
                                                    @input="setStyleVariable"
                                                    menuPosition="bottom"
                                                    model="hex"
                                                    v-model="colors_variables.side_bar.primary_text_color"
                                            ></verte>

                                        </div>
                                        <div class="col-6 form-group">
                                            <label for>Active menu background</label><br/>
                                            <verte
                                                    @input="setStyleVariable"
                                                    menuPosition="bottom"
                                                    model="hex"
                                                    v-model="colors_variables.side_bar.secondary_color"
                                            ></verte>

                                        </div>


                                        <div class="col-6 form-group">
                                            <label for>Active menu text color</label><br/>
                                            <verte
                                                    @input="setStyleVariable"
                                                    menuPosition="bottom"
                                                    model="hex"
                                                    v-model="colors_variables.side_bar.secondary_text_color"
                                            ></verte>


                                        </div>

                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card flat>
                                <v-card-text>
                                    <div class="row">
                                        <div class="col-6 form-group">
                                            <label for>Background color</label><br/>
                                            <verte
                                                    @input="setStyleVariable"
                                                    menuPosition="bottom"
                                                    model="hex"
                                                    v-model="colors_variables.header.primary_color"
                                            ></verte>

                                        </div>
                                        <div class="col-6 form-group">
                                            <label for>Menu text color</label><br/>
                                            <verte
                                                    @input="setStyleVariable"
                                                    menuPosition="bottom"
                                                    model="hex"
                                                    v-model="colors_variables.header.primary_text_color"
                                            ></verte>

                                        </div>
                                        <div class="col-6 form-group">
                                            <label for>Active menu background</label><br/>
                                            <verte
                                                    @input="setStyleVariable"
                                                    menuPosition="bottom"
                                                    model="hex"
                                                    v-model="colors_variables.header.secondary_color"
                                            ></verte>

                                        </div>


                                        <div class="col-6 form-group">
                                            <label for>Active menu text color</label><br/>
                                            <verte
                                                    @input="setStyleVariable"
                                                    menuPosition="bottom"
                                                    model="hex"
                                                    v-model="colors_variables.header.secondary_text_color"
                                            ></verte>


                                        </div>

                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card flat>
                                <v-card-text>
                                    <div class="row">
                                        <div class="col-6 form-group">
                                            <label for>Background color</label><br/>
                                            <verte
                                                    @input="setStyleVariable"
                                                    menuPosition="bottom"
                                                    model="hex"
                                                    v-model="colors_variables.button.primary_color"
                                            ></verte>

                                        </div>
                                        <div class="col-6 form-group">
                                            <label for>Menu text color</label><br/>
                                            <verte
                                                    @input="setStyleVariable"
                                                    menuPosition="bottom"
                                                    model="hex"
                                                    v-model="colors_variables.button.primary_text_color"
                                            ></verte>

                                        </div>
                                        <div class="col-6 form-group">
                                            <label for>Active menu background</label><br/>
                                            <verte
                                                    @input="setStyleVariable"
                                                    menuPosition="bottom"
                                                    model="hex"
                                                    v-model="colors_variables.button.secondary_color"
                                            ></verte>

                                        </div>
                                        <div class="col-6 form-group">
                                            <label for>Active menu text color</label><br/>
                                            <verte
                                                    @input="setStyleVariable"
                                                    menuPosition="bottom"
                                                    model="hex"
                                                    v-model="colors_variables.button.secondary_text_color"
                                            ></verte>


                                        </div>

                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card flat>
                            <v-card-text>
                              <div class="row pa-6">
                                <div class="col-12 form-group">
                                  <label for>Logo image</label>
                                  <b-form-file
                                      v-model="setting.email_logo_image_file"
                                      placeholder="Choose a file or drop it here..."
                                      drop-placeholder="Drop file here..."
                                  ></b-form-file>
                                  <br>
                                  <img
                                      v-if="setting.email_logo_path && setting.email_logo_path['thumb']"
                                      style="height: 200px"
                                      :src="setting.email_logo_path['real']"
                                      alt
                                      class="mt-5"
                                  />
                                </div>
                                <div class="col-4 mt-2 form-group">
                                  <label for>Header Background Color</label><br/>
                                  <verte
                                      menuPosition="bottom"
                                      model="hex"
                                      v-model="colors_variables.email.header_background_color"
                                  ></verte>

                                </div>
                                <div class="col-4 form-group">
                                  <label for>Header Text Color</label><br/>
                                  <verte
                                      @input="setStyleVariable"
                                      menuPosition="bottom"
                                      model="hex"
                                      v-model="colors_variables.email.header_text_color"
                                  ></verte>

                                </div>

                                <div class="col-4 form-group">
                                  <label for>Footer Background Color</label><br/>
                                  <verte
                                      @input="setStyleVariable"
                                      menuPosition="bottom"
                                      model="hex"
                                      v-model="colors_variables.email.footer_background_color"
                                  ></verte>

                                </div>

                                <div class="col-4 form-group">
                                  <label for>Footer text Color</label><br/>
                                  <verte
                                      @input="setStyleVariable"
                                      menuPosition="bottom"
                                      model="hex"
                                      v-model="colors_variables.email.footer_text_color"
                                  ></verte>

                                </div>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>
                    </v-tabs>


                </div>
            </v-card-text>
        </v-card>
    </v-app>
</template>

<script>
    export default {
        props: ["setting", "colors_variables"],
        name: "CollegeSetting",
        methods: {
            setStyleVariable() {

                document.documentElement.style.setProperty('--sidebar-primary-color', this.colors_variables.side_bar.primary_color);
                document.documentElement.style.setProperty('--sidebar-secondary-color', this.colors_variables.side_bar.secondary_color);
                document.documentElement.style.setProperty('--sidebar-primary-text-color', this.colors_variables.side_bar.primary_text_color);
                document.documentElement.style.setProperty('--sidebar-secondary-text-color', this.colors_variables.side_bar.secondary_text_color);

                document.documentElement.style.setProperty('--header-primary-color', this.colors_variables.header.primary_color);
                document.documentElement.style.setProperty('--header-secondary-color', this.colors_variables.header.secondary_color);
                document.documentElement.style.setProperty('--header-primary-text-color', this.colors_variables.header.primary_text_color);
                document.documentElement.style.setProperty('--header-secondary-text-color', this.colors_variables.header.secondary_text_color);


                document.documentElement.style.setProperty('--button-primary-color', this.colors_variables.button.primary_color);
                document.documentElement.style.setProperty('--button-secondary-color', this.colors_variables.button.secondary_color);
                document.documentElement.style.setProperty('--button-primary-text-color', this.colors_variables.button.primary_text_color);
                document.documentElement.style.setProperty('--button-secondary-text-color', this.colors_variables.button.secondary_text_color);
            },
            resetColors(){
                this.$confirm({
                    message: `Are you sure you want Restore Default Theme?`,
                    button: {
                        no: "No",
                        yes: "Yes"
                    },
                    callback: confirm => {
                        if (confirm) {
                            this.$emit('reset_theme');
                        }
                    }
                });

            }
        },
    }
</script>

