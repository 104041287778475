import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class TemplatesService {

      #api = null;

      constructor() {
            this.#api = `${API_URL}user/templates`;

      }
      all() {
            let url = `${this.#api}-all`;
            return apiService.get(url);
      }


      paginate(type = null) {
            let url = `${this.#api}`;
            if (type)
                  url = url + '?type=' + type;
            return apiService.get(url);
      }

      update(id, data) {
            let url = `${this.#api}/${id}/update`
            return apiService.post(url, data);

      }

      store(data) {
            let url = `${this.#api}`
            return apiService.post(url, data);

      }

      show(id) {
            let url = `${this.#api}/${id}`
            return apiService.get(url);
      }

      getByProgram(programId,data={}) {
            let url = `${this.#api}/program/${programId}`;
            let param ={
                  params: data
            }
            return apiService.query(url,param);
      }

      delete(id) {
            let url = `${this.#api}/${id}`
            return apiService.delete(url);
      }

      createByProgram(data){
            let url = `${this.#api}/create-by-program`
            return apiService.post(url, data);
      }

      checkDuplicateByProgram(data){
            let url = `${this.#api}/check-duplicate-by-program`;
            let param ={
                  params: data
            }
            return apiService.query(url,param);
      }

      setAsDefault(id, programId){
            let url = `${this.#api}/${id}/set-as-default/${programId}`;
            return apiService.post(url);
      }

      getByType(programId, type){
            let url = `${this.#api}/${programId}/get-by/${type}`;
            return apiService.get(url);
      }
}
