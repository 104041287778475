<template>
  <v-app>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4> Manage teacher menu</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Teacher menu
                </div>
              </div>

            </div>
          </div>
          <div class="card-body">
            <div class="row">

              <div class="col-6">
                <v-checkbox
                    v-model="settingDetail.teacher_menu_access"
                    label="Online classroom"
                    color="primary"
                    value="online_classroom"
                    hide-details>
                  <template v-slot:label>
                    <span class="font-weight-medium">
                      Online classroom
                    </span>
                  </template>
                </v-checkbox>
              </div>
              <div class="col-6">
                <v-checkbox
                    v-model="settingDetail.teacher_menu_access"
                    label="Lesson planner"
                    color="primary"
                    value="lesson_planner"
                    hide-details>
                  <template v-slot:label>
                    <span class="font-weight-medium">
                      Lesson planner
                    </span>
                  </template>
                </v-checkbox>
              </div>
              <div class="col-6">
                <v-checkbox
                    v-model="settingDetail.teacher_menu_access"
                    label="Assignments"
                    color="primary"
                    value="assignments"
                    hide-details>
                  <template v-slot:label>
                    <span class="font-weight-medium">
                      Assignments
                    </span>
                  </template>
                </v-checkbox>
              </div>
              <div class="col-6">
                <v-checkbox
                    v-model="settingDetail.teacher_menu_access"
                    label="Classes"
                    color="primary"
                    value="classes"
                    hide-details>
                  <template v-slot:label>
                    <span class="font-weight-medium">
                      Classes
                    </span>
                  </template>
                </v-checkbox>
              </div>
              <div class="col-6">
                <v-checkbox
                    v-model="settingDetail.teacher_menu_access"
                    label="Courses"
                    color="primary"
                    value="courses"
                    hide-details>
                  <template v-slot:label>
                    <span class="font-weight-medium">
                      Courses
                    </span>
                  </template>
                </v-checkbox>
              </div>
              <div class="col-6">
                <v-checkbox
                    v-model="settingDetail.teacher_menu_access"
                    label="Timetable"
                    color="primary"
                    value="timetable"
                    hide-details>
                  <template v-slot:label>
                    <span class="font-weight-medium">
                      Timetable
                    </span>
                  </template>
                </v-checkbox>
              </div>
              <div class="col-6">
                <v-checkbox
                    v-model="settingDetail.teacher_menu_access"
                    label="Exam & Results"
                    color="primary"
                    value="exam_and_results"
                    hide-details>
                  <template v-slot:label>
                    <span class="font-weight-medium">
                     Exam & Results
                    </span>
                  </template>
                </v-checkbox>
              </div>
              <div class="col-6">
                <v-checkbox
                    v-model="settingDetail.teacher_menu_access"
                    label="Leave request"
                    color="primary"
                    value="leave_request"
                    hide-details>
                  <template v-slot:label>
                    <span class="font-weight-medium">
                     Leave request
                    </span>
                  </template>
                </v-checkbox>
              </div>
              <div class="col-6">
                <v-checkbox
                    v-model="settingDetail.teacher_menu_access"
                    label="Library resource"
                    color="primary"
                    value="library_resource"
                    hide-details>
                  <template v-slot:label>
                    <span class="font-weight-medium">
                    Library resource
                    </span>
                  </template>
                </v-checkbox>
              </div>
            </div>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                class="text-white ml-2 btn btn-primary"
                depressed :loading="loading"
                dark
                @click="updateSetting()">
              Save
            </v-btn>
          </v-card-actions>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import SiteSettingService from "@/core/services/site-setting/SiteSettingService";
const siteSetting=new SiteSettingService();
export default
{

  data(){
    return{
      loading:false,
      settingDetail: {
        teacher_menu_access:[],
      },
    }
  },
  methods:{
    updateSetting() {
      this.loading = true;
      if(this.settingDetail.teacher_menu_access.length > 0){
        this.settingDetail.teacher_menu_access=this.settingDetail.teacher_menu_access.join();
      }else{
        this.settingDetail.teacher_menu_access=null
      }
      siteSetting.update(this.setting.id, this.settingDetail)
          .then(response => {
            this.$snotify.success("Information updated");
            this.$emit("refresh");
            this.getSetting();
          })
          .catch(error => {
            console.log(error)
          })
          .finally(this.loading = false);

          },
    getSetting() {
      siteSetting
          .show()
          .then(response => {
            this.setting = response.data;
            if(this.setting.teacher_menu_access){
              this.settingDetail.teacher_menu_access=this.setting.teacher_menu_access.split(',');
            }else{
              this.settingDetail.teacher_menu_access=[];
            }
      });
    },
  },
  mounted() {
    this.getSetting();
  }
}
</script>