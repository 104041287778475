<template>
  <v-app class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="card-title">
            <div class="row">
              <div class="col-6">
                <h4>
                  Settings
                </h4>
                <router-link :to="{ name: 'dashboard' }">
                  Dashboard
                </router-link>
                \
                Settings
              </div>

              <div class="col-6 float-right" v-if="checkIsAccessible('site-setting', 'create') || checkIsAccessible('site-setting', 'edit')">
                <v-btn
                    :disabled="sizeExceed" v-if="!(tab==13 || tab==12 ||  tab==14)"
                    :loading="isLoading"
                    @click="saveSetting"
                    class="text-white float-right btn btn-primary"
                    depressed
                >{{ setting && setting.id ? "Update" : "Save" }} settings
                </v-btn>
              </div>
              <div class="col-12">
                <v-tabs
                    class="justify-start"
                    v-model="tab"
                    vertical
                >
                  <v-tabs-slider color="yellow"></v-tabs-slider>

                  <v-tab class="justify-start">
                    Global
                  </v-tab>
                  <v-tab class="justify-start">
                    Social medias
                  </v-tab>

                  <v-tab class="justify-start">
                    Widget & API
                  </v-tab>
                  <v-tab class="justify-start">
                    Storage
                  </v-tab>
                  <v-tab class="justify-start">
                    Email SMTP
                  </v-tab>
                  <v-tab class="justify-start">
                    Payment gateways
                  </v-tab>
                  <v-tab class="justify-start">
                    SMS providers
                  </v-tab>
                  <v-tab class="justify-start">
                    Theme style
                  </v-tab>
                  <v-tab class="justify-start">
                    Room setting
                  </v-tab>
                  <v-tab class="justify-start">
                    Tax setting
                  </v-tab>
                  <v-tab class="justify-start">
                    Library setting
                  </v-tab>
                  <v-tab class="justify-start">
                    Zoom setting
                  </v-tab>
<!--                  <v-tab class="justify-start">
                    ID card setting
                  </v-tab>-->
<!--                  <v-tab class="justify-start">
                    Online application/enrollment
                  </v-tab>-->
                  <v-tab class="justify-start" >
                   Invoice customization
                  </v-tab>
                  <v-tab class="justify-start" @click="changeTeacherMenu">
                    Teacher menu access
                  </v-tab>
                  <v-tab class="justify-start" >
                    Maintenance mode
                  </v-tab>
<!--                  <v-tab class="justify-start" >
                   Character certificate
                  </v-tab>-->
                  <v-tab-item>
                    <v-card flat>
                      <v-card-text>
                        <v-tabs class="justify-start" vertical>
                          <v-tab class="justify-start">
                            General
                          </v-tab>
                          <v-tab class="justify-start">
                            Logo & favicon
                          </v-tab>
                          <v-tab class="justify-start">
                            Principal & director
                          </v-tab>
                          <v-tab class="justify-start">
                            Principal signature
                          </v-tab>

                          <v-tab class="justify-start">
                            Contact
                          </v-tab>
                          <v-tab class="justify-start">
                            Zoom
                          </v-tab>
                          <v-tab class="justify-start">
                            Action button
                          </v-tab>
                          <v-tab class="justify-start">
                            Student fee
                          </v-tab>
                          <v-tab-item>
                            <v-card flat>
                              <v-card-text>
                                <div class="row">
                                  <div class="col-6 form-group">
                                      <v-text-field dense label="Name"
                                                    outlined
                                        v-model="setting.college_name"
                                      ></v-text-field>
                                  </div>
                                  <div class="col-6 form-group">
                                    <v-text-field dense label="Tagline"
                                                   outlined
                                        v-model="setting.slogan"
                                  ></v-text-field>
                                  </div>

                                  <!--                                  <div class="col-6 form-group">-->
                                  <!--                                    <v-text-field v-model="setting.vat_number" outlined dense-->
                                  <!--                                                  label="VAT/PAN No."></v-text-field>-->
                                  <!--                                    &lt;!&ndash; <span v-if="$v.setting.tagline.$error" class="text-danger">Enter valid phone no</span> &ndash;&gt;-->
                                  <!--                                  </div>-->
                                  <div class="col-6 form-group">
                                    <v-text-field dense label="Website tagline"
                                                  outlined
                                                  v-model="setting.tagline"></v-text-field>
                                    <!-- <span v-if="$v.setting.tagline.$error" class="text-danger">Enter valid phone no</span> -->
                                  </div>
                                  <div class="col-6 form-group">
                                    <v-text-field dense label="Website"
                                                  outlined
                                                  v-model="setting.website"></v-text-field>
                                    <!--                            <span v-if="$v.setting.website.$error" class="text-danger">Website is required.</span>-->
                                  </div>
                                  <!--                                  <div class="col-6 form-group">-->
                                  <!--                                    <v-text-field v-model="setting.address" outlined dense-->
                                  <!--                                                  label="Address"></v-text-field>-->
                                  <!--                                    &lt;!&ndash; <span v-if="$v.setting.address.$error" class="text-danger">Address is required.</span> &ndash;&gt;-->
                                  <!--                                  </div>-->
                                  <div class="col-6 form-group">
                                    <v-text-field dense
                                                  label="Copyright text" outlined
                                                  v-model="setting.copy_right_text"></v-text-field>
                                    <!-- <span v-if="$v.setting.phone.$error" class="text-danger">Website Title is required.</span> -->
                                  </div>

                                  <div class="col-6 form-group">
                                    <v-select
                                        :items="academicYears"
                                        dense
                                        item-text="year"
                                        item-value="id"
                                        label="Current year"
                                        outlined
                                        v-model="setting.academic_year_id">

                                    </v-select>
                                    <!-- <span v-if="$v.setting.phone.$error" class="text-danger">Website Title is required.</span> -->
                                  </div>
                                  <div class="col-6 form-group">
                                    <v-text-field
                                        dense
                                        label="Default Date Format"
                                        outlined
                                        v-model="setting.date_format">
                                    </v-text-field>
                                    <!-- <span v-if="$v.setting.phone.$error" class="text-danger">Website Title is required.</span> -->
                                  </div>
                                  <div class="col-6 form-group">
                                    <v-text-field
                                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="visible_password ? 'text' : 'password'"
                                        dense
                                        label="Application password"
                                        outlined
                                        @click:append="visible_password = !visible_password"
                                        v-model="setting.application_password">
                                    </v-text-field>
                                    <!-- <span v-if="$v.setting.phone.$error" class="text-danger">Website Title is required.</span> -->
                                  </div>

                                </div>
                              </v-card-text>
                            </v-card>
                          </v-tab-item>

                          <v-tab-item>
                            <v-card flat>
                              <v-card-text>
                                <div class="row">
                                  <div class="col-12 form-group">
                                    <v-file-input @change="checkSize($event)" dense
                                                  label="Logo"
                                                  outlined
                                                  v-model="setting.logo"></v-file-input>

                                    <img
                                        :src="setting.logo_path['thumb']"
                                        alt
                                        class="mt-5"
                                        style="height:100px"
                                        v-if="setting.logo_path && setting.logo_path['thumb']"
                                    />
                                  </div>


                                  <div class="col-12 form-group">
                                    <v-file-input @change="checkSize($event)" dense
                                                  label="Favicon"
                                                  outlined
                                                  v-model="setting.fav_icon"></v-file-input>
                                    <img
                                        :src="setting.fav_icon_path['real']"

                                        alt
                                        class="mt-5"
                                        style=""
                                        v-if="setting.fav_icon_path && setting.fav_icon_path['real']"
                                    />
                                  </div>
                                  <span class="text-danger" v-if="sizeExceed">Please  upload file upto 20MB</span>
                                </div>
                              </v-card-text>
                            </v-card>
                          </v-tab-item>

                          <v-tab-item>
                            <v-card flat>
                              <v-card-text>
                                <div class="row">

                                  <div class="col-12 form-group">
                                    <label for="">Principal</label>
                                    <template v-if="setting.principal">
                                      <v-card
                                          class="mx-auto mb-2"
                                          max-width="400"
                                      >
                                        <v-card-actions>
                                          <v-list-item class="grow">
                                            <v-list-item-avatar color="grey darken-3">
                                              <v-img
                                                  class="elevation-6"
                                                  alt=""
                                                  src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                                              ></v-img>
                                            </v-list-item-avatar>

                                            <v-list-item-content>
                                              <v-list-item-title>{{ setting.principal.suggestion_list_name }}
                                              </v-list-item-title>
                                              <v-list-item-subtitle>{{ setting.principal.email }}</v-list-item-subtitle>
                                            </v-list-item-content>


                                          </v-list-item>
                                        </v-card-actions>
                                      </v-card>
                                    </template>
                                    <v-autocomplete
                                        v-model="setting.principal_id"
                                        :items="users"
                                        :loading="isBusy"
                                        outlined
                                        dense
                                        :search-input.sync="searchName"
                                        item-value="id"
                                        item-text="display_text"
                                        label="User CRN or email"
                                    ></v-autocomplete>

                                  </div>
                                  <div class="col-12 form-group">
                                    <label for="">Director</label>
                                    <template v-if="setting.director">
                                      <v-card
                                          class="mx-auto mb-2"
                                          max-width="400"
                                      >
                                        <v-card-actions>
                                          <v-list-item class="grow">
                                            <v-list-item-avatar color="grey darken-3">
                                              <v-img
                                                  class="elevation-6"
                                                  alt=""
                                                  src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                                              ></v-img>
                                            </v-list-item-avatar>

                                            <v-list-item-content>
                                              <v-list-item-title>{{ setting.director.suggestion_list_name }}
                                              </v-list-item-title>
                                              <v-list-item-subtitle>{{ setting.director.email }}</v-list-item-subtitle>
                                            </v-list-item-content>


                                          </v-list-item>
                                        </v-card-actions>
                                      </v-card>
                                    </template>
                                    <v-autocomplete
                                        v-model="setting.director_id"
                                        :items="users"
                                        :loading="isBusy"
                                        outlined
                                        dense
                                        :search-input.sync="searchDirector"
                                        item-value="id"
                                        item-text="display_text"
                                        label="User CRN or email"
                                    ></v-autocomplete>

                                  </div>
                                </div>

                              </v-card-text>
                            </v-card>
                          </v-tab-item>

                          <v-tab-item>
                            <v-card flat>
                              <v-card-text>
                                <div class="row">

                                  <div class="col-12 form-group">
                                    <v-file-input @change="checkSize($event)" dense
                                                  label="Principal signature"
                                                  outlined
                                                  v-model="setting.principal_signature"></v-file-input>

                                    <img
                                        :src="setting.principal_signature_path['thumb']"
                                        alt
                                        class="mt-5"
                                        style="height:100px"
                                        v-if="setting.principal_signature_path && setting.principal_signature_path['thumb']"
                                    />
                                  </div>
                                </div>

                              </v-card-text>
                            </v-card>
                          </v-tab-item>

                          <v-tab-item>
                            <v-card flat>
                              <v-card-text>
                                <div class="row">
                                  <div class="col-12 form-group">
                                    <v-text-field dense label="Phone"
                                                  outlined
                                                  v-model="setting.phone"></v-text-field>
                                    <span class="text-danger"
                                          v-if="$v.setting.phone.$error">This information is required</span>
                                  </div>
                                  <div class="col-12 form-group">
                                    <v-text-field dense label="Email"
                                                  outlined
                                                  v-model="setting.email"></v-text-field>
                                    <span class="text-danger"
                                          v-if="$v.setting.email.$error">This information is required</span>
                                  </div>
                                  <div class="col-12 form-group">
                                    <v-text-field dense label="Address"
                                                  outlined
                                                  v-model="setting.address"></v-text-field>
                                    <!-- <span v-if="$v.setting.address.$error" class="text-danger">Address is required.</span> -->
                                  </div>
                                  <div class="col-12 form-group">
                                    <v-text-field dense label="Google map embed URL"
                                                  outlined
                                                  v-model="setting.map_url"></v-text-field>
                                    <span class="text-danger"
                                          v-if="$v.setting.map_url.$error">This information is required</span>
                                  </div>

                                </div>
                              </v-card-text>
                            </v-card>
                          </v-tab-item>

                          <v-tab-item>
                            <v-card flat>
                              <v-card-text>
                                <div class="row">
                                  <div class="col-12 form-group">
                                    <v-text-field dense
                                                  label="Zoom URL" outlined
                                                  v-model="setting.zoom_link"></v-text-field>
                                    <span class="text-danger"
                                          v-if="$v.setting.zoom_link.$error">This information is required</span>
                                  </div>
                                </div>
                              </v-card-text>
                            </v-card>
                          </v-tab-item>

                          <v-tab-item>
                            <v-card flat>
                              <v-card-text>
                                <div class="row">
                                  <div class="col-12 form-group">
                                    <v-text-field
                                        dense
                                        label="Action button text" outlined
                                        v-model="setting.action_button_text"></v-text-field>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-12 form-group">
                                    <v-text-field
                                        dense
                                        label="Action button link" outlined
                                        v-model="setting.action_button_link"></v-text-field>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-12 form-group">
                                    <v-select
                                        :items="['yes','no']"
                                        dense
                                        label="Open in new tab"
                                        outlined
                                        v-model="setting.action_button_link_action"
                                    ></v-select>
                                  </div>
                                  <div class="col-12 form-group">
                                    <v-switch label="Show action button"
                                              v-model="setting.show_action_button"></v-switch>
                                  </div>
                                </div>
                              </v-card-text>
                            </v-card>
                          </v-tab-item>

                          <v-tab-item>
                            <v-card flat>
                              <v-card-text class="ml-8 w-50">
                                <div class="row">
                                  <div class="col-12 form-group">
                                    <v-switch
                                        label="Enable auto increment fee"
                                        v-model="setting.enable_auto_increment_fee"
                                    ></v-switch>
                                  </div>

                                  <div class="col-12 form-group" v-if="setting.enable_auto_increment_fee">
                                    <v-select
                                        :items="['fixed','percentage']"
                                        dense
                                        label="Auto increment fee type"
                                        outlined
                                        v-model="setting.auto_increment_fee_type"
                                    ></v-select>
                                  </div>

                                  <div class="col-12 form-group"  v-if="setting.enable_auto_increment_fee">
                                    <v-text-field
                                        type="number"
                                        @input="checkIncrementFeeType"
                                        v-model="setting.auto_increment_fee"
                                        :label="`Auto increment fee ${setting.auto_increment_fee_type == 'fixed' ? 'amount' : 'percentage'}`"
                                        outlined
                                        dense
                                    ></v-text-field>
                                  </div>
                                </div>
                              </v-card-text>
                            </v-card>
                          </v-tab-item>

                        </v-tabs>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card flat>
                      <v-card-text>
                        <div class="row">
                          <div class="col-12 float-right">
                            <h3>Social media</h3>
                          </div>
                          <div class="col-6 form-group">
                            <v-text-field dense label="Facebook" outlined
                                          v-model="setting.facebook"></v-text-field>
                            <span class="text-danger" v-if="$v.setting.facebook.$error">This information is required</span>
                          </div>
                          <div class="col-6 form-group">
                            <v-text-field dense label="Twitter" outlined
                                          v-model="setting.twitter"></v-text-field>
                            <span class="text-danger" v-if="$v.setting.twitter.$error">This information is required</span>
                          </div>
                          <div class="col-6 form-group">
                            <v-text-field dense label="Youtube" outlined
                                          v-model="setting.youtube"></v-text-field>
                            <span class="text-danger" v-if="$v.setting.youtube.$error">This information is required</span>
                          </div>
                          <div class="col-6 form-group">
                            <v-text-field dense label="Instagram" outlined
                                          v-model="setting.instagram"></v-text-field>
                            <span class="text-danger" v-if="$v.setting.instagram.$error">This information is required</span>
                          </div>

                          <div class="col-6 form-group">
                            <v-text-field dense label="LinkedIn" outlined
                                          v-model="setting.linkedin"></v-text-field>
                            <span class="text-danger" v-if="$v.setting.linkedin.$error">This information is required</span>
                          </div>
                          <div class="col-6 form-group">
                            <v-text-field dense label="Tiktok" outlined
                                          v-model="setting.tiktok"></v-text-field>
                            <span class="text-danger" v-if="$v.setting.tiktok.$error">This information is required</span>
                          </div>
                          <div class="col-6 form-group">
                            <v-text-field dense label="Pininterest" outlined
                                          v-model="setting.pininterest"></v-text-field>
                            <span class="text-danger" v-if="$v.setting.pininterest.$error">This information is required</span>
                          </div>
                          <div class="col-6 form-group">
                            <v-text-field dense label="Viber" outlined
                                          v-model="setting.viber"></v-text-field>
                            <span class="text-danger" v-if="$v.setting.viber.$error">This information is required</span>
                          </div>
                          <div class="col-6 form-group">
                            <v-text-field dense label="Whatsapp" outlined
                                          v-model="setting.whatsapp"></v-text-field>
                            <span class="text-danger" v-if="$v.setting.whatsapp.$error">This information is required</span>
                          </div>


                        </div>
                      </v-card-text>
                    </v-card>

                  </v-tab-item>


                  <v-tab-item>
                    <widget :facebook_chat_widgets="facebook_chat_widgets"
                            :setting="setting"
                            @update_setting="updateSetting"></widget>
                  </v-tab-item>
                  <v-tab-item>
                    <storage :setting="setting" @update_setting="updateSetting"></storage>
                  </v-tab-item>
                  <v-tab-item>
                    <smtp-setting :setting="setting" @update_setting="updateSetting"></smtp-setting>
                  </v-tab-item>
                  <v-tab-item>
                    <payment-gateway :setting="setting"></payment-gateway>
                  </v-tab-item>
                  <v-tab-item>
                    <sms-provider :setting="setting"></sms-provider>
                  </v-tab-item>
                  <v-tab-item>
                    <college-setting :colors_variables="colors_variables"
                                     :setting="setting"
                                     @reset_theme="resetTheme"
                    ></college-setting>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card flat>
                      <v-card-text>
                        <div class="row">
                          <div class="col-12 float-right">
                            <h3>Room setting</h3>
                          </div>
                          <div class="col-12 form-group">
                            <v-switch label="Enable level" v-model="setting.enable_level">
                            </v-switch>
                          </div>
                          <div class="col-12 form-group">
                            <v-switch label="Enable building no."
                                      v-model="setting.enable_buildings">
                            </v-switch>
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <tax :setting="setting"></tax>
                  </v-tab-item>
                  <v-tab-item>
                    <library :setting="setting"></library>
                  </v-tab-item>
                  <v-tab-item>
                    <zoom :setting="setting" @update_setting="updateSetting"></zoom>
                  </v-tab-item>
<!--                  <v-tab-item>
                    <id-card-template :setting="setting" @update_setting="updateSetting"></id-card-template>
                  </v-tab-item>-->
<!--                  <v-tab-item>
                    <pre-registration :setting="setting" @update_setting="updateSetting"></pre-registration>
                  </v-tab-item>-->
                  <v-tab-item>
                    <invoice-customization></invoice-customization>
                  </v-tab-item>
                  <v-tab-item>
                    <teacher-access-menu  ref="teacher-access-menu"  v-if="setting" @refresh="getSetting"></teacher-access-menu>
                  </v-tab-item>
                  <v-tab-item>
                    <maintenance-mode v-if="setting" :setting="setting" @refresh="getSetting"></maintenance-mode>
                  </v-tab-item>
<!--                  <v-tab-item>
                    <character-certificate></character-certificate>
                  </v-tab-item>-->
                </v-tabs>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import SiteSettingService from "@/core/services/site-setting/SiteSettingService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import {email, minLength, numeric, url} from "vuelidate/lib/validators";
import Widget from "@/view/pages/setting/Widget";
import Storage from "@/view/pages/setting/Storage";
import CollegeSetting from "./CollegeSetting";
import SmtpSetting from "./Smtp";
import PaymentGateway from "@/view/pages/setting/PaymentGateway";
import SmsProvider from "@/view/pages/setting/SmsProvider";
import Tax from '@/view/pages/setting/Tax';
import Library from '@/view/pages/setting/Library';
import Zoom from "@/view/pages/setting/Zoom";
import IdCardTemplate from "@/view/pages/setting/templates/IdCardTemplate";
import PreRegistration from "@/view/pages/setting/PreRegistration";
import InvoiceCustomization from "@/view/pages/setting/InvoiceCustomization.vue";
import CharacterCertificate from "@/view/pages/setting/certificate/CharacterCertificate.vue";
import TeacherAccessMenu from "@/view/pages/setting/TeacherAccessMenu.vue";
import MaintenanceMode from "@/view/pages/setting/MaintenanceMode.vue";

const siteSettingService = new SiteSettingService();
const academicYearService = new AcademicYearService();
import UserService from "@/core/services/user/UserService";

const userService = new UserService();
export default {
  name: "setting",
  validations: {
    setting: {
      phone: {minLength: minLength(10)},
      email: {email},
      whatsapp: {numeric, minLength: minLength(10)},
      viber: {numeric, minLength: minLength(10)},
      pininterest: {url},
      tiktok: {url},
      linkedin: {url},
      instagram: {url},
      youtube: {url},
      twitter: {url},
      facebook: {url},
      zoom_link: {url},
      map_url: {url}
    }
  },
  components: {
    Widget,
    Storage,
    CollegeSetting,
    SmtpSetting,
    PaymentGateway,
    SmsProvider,
    Tax,
    Library,
    Zoom,
    IdCardTemplate,
    PreRegistration,
    InvoiceCustomization,
    CharacterCertificate,
    TeacherAccessMenu,
    MaintenanceMode
  },
  data() {
    return {
      show2: false,
      tab: null,
      file1: null,
      visible_password: false,
      button: null,
      file2: null,
      edit: false,
      isReload: false,
      isLoading: false,
      isBusy: false,
      sizeExceed: false,
      academicYears: [],
      users: [],
      searchName: null,
      searchDirector: null,
      facebook_chat_widgets: {},
      colors_variables: {
        login: {
          primary_color: '#009387',
          primary_text_color: '#fff',
          secondary_color: '#007190',
          secondary_text_color: '#fff',
        },
        header: {
          primary_color: "#009387",
          primary_text_color: "#ffffff",
          secondary_color: "#007190",
          secondary_text_color: "#ffffff",

        },
        side_bar: {
          bg_color: "#009387",
          primary_color: "#009387",
          primary_text_color: "#fff",
          secondary_color: "#007190",
          secondary_text_color: "#fff"

        },
        button: {
          primary_color: "#009387",
          primary_text_color: "#ffffff",
          secondary_color: "#007190",
          secondary_text_color: "#ffffff"

        },
        email: {
          header_background_color: "#00458f",
          header_text_color: "#00458f",
          footer_background_color: "#00458f",
          footer_text_color: "#00458f",
        }

      },
      search: {
        name: null,
      },
      setting: {
        phone: null,
        email: null,
        website: null,
        address: null,
        whatsapp: null,
        viber: null,
        pininterest: null,
        tiktok: null,
        linkedin: null,
        instagram: null,
        youtube: null,
        twitter: null,
        facebook: null,
        college_name: null,
        academic_year_id: null,
        enrollment_year_id: null,
        copy_right_text: null,
        zoom_link: null,
        map_url: null,
        storage_type: 'local',
        storage_access_key: null,
        storage_secret_key: null,
        storage_region: null,
        storage_bucket_name: null,
        storage_url: null,
        tagline: null,
        logo: null,
        fav_icon: null,
        action_button_text: null,
        action_button_link: null,
        action_button_link_action: null,
        enable_auto_increment_fee: false,
        auto_increment_fee_type: false,
        auto_increment_fee: false,
      }
    };
  },
  mounted() {
    this.getSetting();
    this.getAcademicYears()
  },
  watch: {
    searchName(newValue, oldValue) {
      this.search.name = newValue;
      // Lazily load input items
      this.getUsers();

    },
    searchDirector(newValue, oldValue) {
      this.search.name = newValue;
      // Lazily load input items
      this.getUsers();

    },
  },
  methods: {
    getUsers() {
      this.isBusy = true;
      userService.paginate(this.search).then(response => {
        this.isBusy = false;
        this.users = response.data.data;
        this.users.map(ele => {
          if (ele.personal_id && ele.personal_id != undefined && ele.personal_id != null) {
            ele.display_text = `${ele.personal_id} - ${ele.full_name} - ${ele.email}`
            this.users.push(ele)
          }
        })

      });
    },
    getSetting() {
      siteSettingService.show().then(response => {
        this.setting = response.data;
        if (this.setting && this.setting.id) {
          this.edit = true;
          if (this.setting.facebook_chat_widgets)
            this.facebook_chat_widgets = JSON.parse(this.setting.facebook_chat_widgets);
          if (this.setting.colors_variables) {
            this.colors_variables = JSON.parse(this.setting.colors_variables);
            if(this.colors_variables.email == "undefined" || this.colors_variables.email == undefined) {
              this.colors_variables.email = {
                header_background_color: "#00458f",
                header_text_color: "#00458f",
                footer_background_color: "#00458f",
                footer_text_color: "#00458f",
              }
            }
            this.setStyleVariable();
          }
        }
      });
    },
    getAcademicYears() {
      academicYearService.paginate().then(response => {
        this.academicYears = response.data.data;
      });
    },

    checkIncrementFeeType() {
        if(this.setting.auto_increment_fee_type == 'percentage' && parseInt(this.setting.auto_increment_fee) > 100) {
          this.$snotify.error("Percentage amount should not be greater than 100");
          this.sizeExceed = true;
        } else {
          this.sizeExceed = false;
        }
    },

    saveSetting() {
      this.$v.setting.$touch();
      if (this.$v.setting.$error) {
        setTimeout(() => {
          this.$v.setting.$reset();
        }, 3000);
      } else {
        this.setting.facebook_chat_widgets = JSON.stringify(this.facebook_chat_widgets);
        this.setting.colors_variables = this.colors_variables ? JSON.stringify(this.colors_variables) : null;
        if (this.edit) {
          this.updateSetting();
        } else {
          this.createSetting();
        }
      }
    },
    setStyleVariable() {


      document.documentElement.style.setProperty('--sidebar-primary-color', this.colors_variables.side_bar.primary_color);
      document.documentElement.style.setProperty('--sidebar-secondary-color', this.colors_variables.side_bar.secondary_color);
      document.documentElement.style.setProperty('--sidebar-primary-text-color', this.colors_variables.side_bar.primary_text_color);
      document.documentElement.style.setProperty('--sidebar-secondary-text-color', this.colors_variables.side_bar.secondary_text_color);

      document.documentElement.style.setProperty('--header-primary-color', this.colors_variables.header.primary_color);
      document.documentElement.style.setProperty('--header-secondary-color', this.colors_variables.header.secondary_color);
      document.documentElement.style.setProperty('--header-primary-text-color', this.colors_variables.header.primary_text_color);
      document.documentElement.style.setProperty('--header-secondary-text-color', this.colors_variables.header.secondary_text_color);


      document.documentElement.style.setProperty('--button-primary-color', this.colors_variables.button.primary_color);
      document.documentElement.style.setProperty('--button-secondary-color', this.colors_variables.button.secondary_color);
      document.documentElement.style.setProperty('--button-primary-text-color', this.colors_variables.button.primary_text_color);
      document.documentElement.style.setProperty('--button-secondary-text-color', this.colors_variables.button.secondary_text_color);

    },
    resetTheme() {
      this.colors_variables = null;
      this.isReload = true;
      this.saveSetting();
    },

    checkSize(file) {
      let size = file.size / 1024 / 1024;
      let mbSize = size.toFixed(2);
      this.sizeExceed = mbSize > 20;
    },
    createSetting() {
      this.isLoading = true;
      let fd = this.generateFd();
      siteSettingService.store(fd).then(response => {
        this.isLoading = false;
        this.$bus.emit('initSetting');
        this.$snotify.success("Information updated");
        this.getSetting();
      }).catch(error => {
        this.isLoading = false;
        this.$snotify.error("Problem occurred");
      })
    },
    updateSetting() {
      this.isLoading = true;
      let fd = this.generateFd();
      siteSettingService.update(this.setting.id, fd).then(response => {
        this.$snotify.success("Information updated");
        if (this.isReload) {
          location.reload()
        } else {
          this.$bus.emit('initSetting');
          this.resetForm();
        }
        this.isLoading = false;
      }).catch(error => {
        this.isLoading = false;
        this.$snotify.error("Problem occurred");
      })
    },
    resetForm() {
      this.setting = {
        phone: null,
        email: null,
        website: null,
        address: null,
        whatsapp: null,
        viber: null,
        pininterest: null,
        tiktok: null,
        linkedin: null,
        instagram: null,
        youtube: null,
        twitter: null,
        facebook: null,
        copy_right_text: null,
        zoom_link: null,
        map_url: null,
        tagline: null,
        logo: null,
        fav_icon: null
      };
      this.getSetting();
    },
    changeTeacherMenu(){
      this.$refs['teacher-access-menu'].getSetting();
    },
    generateFd() {
      let fd = new FormData();
      for (let key in this.setting) {
        if (
            key == "logo" &&
            this.setting["logo"] &&
            this.setting["logo"] != null
        ) {
          fd.append("logo", this.setting[key]);
        } else if (
            key == "fav_icon" &&
            this.setting["fav_icon"] &&
            this.setting["fav_icon"] != null
        ) {
          fd.append("fav_icon", this.setting[key]);
        } else if (
            key == "library_background_image" &&
            this.setting["library_background_image"] &&
            this.setting["library_background_image"] != null
        ) {
          fd.append("library_background_image", this.setting[key]);
        } else if (key == "enable_level" && this.setting["enable_level"]) {
          fd.append("enable_level", this.setting['enable_level'] ? 1 : 0);
        } else if (key == "enable_buildings" && this.setting["enable_buildings"]) {
          fd.append("enable_buildings", this.setting['enable_buildings'] ? 1 : 0);
        } else if (key == "enable_accession_no") {
          fd.append("enable_accession_no", this.setting['enable_accession_no'] ? 1 : 0);
        } else if (key == "is_maintenance_mode") {
          fd.append("is_maintenance_mode", this.setting['is_maintenance_mode'] ? 1 : 0);
        } else if (key == "enable_auto_increment_fee") {
          console.log(this.setting['enable_auto_increment_fee'])
          fd.append("enable_auto_increment_fee", this.setting['enable_auto_increment_fee'] ? 1 : 0);
        } else {
          fd.append(key, this.setting[key] ? this.setting[key] : "");
        }
      }
      return fd;
    },
  }
};
</script>
