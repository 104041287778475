<template>
  <v-app>
    <v-card flat>
      <v-card-text>
        <div class="row">
          <div class="col-12 ">
            <h3>Invoice customization</h3>
            <div v-show="invoice_options.length > 0" class="row" v-for="(option,index) in  invoice_options" :key="index">
              <v-col class="col-3">
                  <label>{{option.option_name}}</label>
              </v-col>
              <div class="col-9" v-if="option.data_type == 'string'">
                  <v-text-field :type="option.data_type" outlined dense :label="option.option_name" v-model="option.value"></v-text-field>
              </div>
              <div class="col-9" v-if="option.data_type == 'text'">
                  <v-textarea :type="option.data_type" outlined dense :label="option.option_name" v-model="option.value"></v-textarea>
              </div>
              <div class="col-9" v-if="option.data_type == 'boolean'">
                  <v-switch :type="option.data_type" outlined dense :label="option.option_name" v-model="option.value"></v-switch>
              </div>
              <div class="col-9" v-if="option.data_type == 'file'">
                  <v-img v-if="option.is_image" :src="option.option_image_path['real']" width="100"></v-img>
                  <v-file-input  prepend-inner-icon="mdi-file"
                                      prepend-icon="" :type="option.data_type" outlined dense :label="option.option_name" v-model="option.value"></v-file-input>
              </div>
            </div>
            <div v-show="invoice_options.length == 0">
              This information is not working.
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions v-show="invoice_options.length > 0">
        <v-spacer></v-spacer>
        <v-btn
            class="text-white ml-2 btn btn-primary"
            depressed :loading="loading"
            dark
            @click="updateInvoiceCustomization()">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-app>
</template>
<script>
import OptionService from "@/core/services/option/OptionService";
const option = new OptionService();
export default
{
  data(){
    return{
      invoice_options:[],
      loading:false,
    }
  },
  methods:{
    getAllInvoiceOptions(){
      option
          .getAllInvoiceOption()
          .then(response => {
            this.invoice_options=response.data.invoice_options
          })
          .catch(error => {
          })
          .finally(() => {
            this.loading=false;
          });
    },
    convertToFormData() {
      let formData = new FormData();
      for (let options of this.invoice_options) {
        for (let key in options) {
          if (key == "data_type" && options[key] == 'file') {
            formData.append("value[]", options["value"]);
          } else if (key == "data_type" && options[key] == 'boolean') {
            formData.append("value[]", options["value"] ? 1 : 0);
          } else {
            if(options[key]){
              formData.append(`${key}[]`,options[key]);
            }
          }

        }
      }
      return formData;
    },
    updateInvoiceCustomization(){
      // let data = this.convertToFormData()
      this.loading=true;
      option
          .updateOption(this.invoice_options)
          .then(response => {
            if(response.data.status == "OK") {
              this.$snotify.success("Invoice updated");
            }
          })
          .catch(() => { this.$snotify.error("Error occurred");})
          .finally(() => {
            this.loading=false;
          });
    }
  },
  mounted() {
    this.getAllInvoiceOptions();
  }
}
</script>
