import { render, staticRenderFns } from "./Smtp.vue?vue&type=template&id=3072d144&scoped=true"
import script from "./Smtp.vue?vue&type=script&lang=js"
export * from "./Smtp.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3072d144",
  null
  
)

export default component.exports