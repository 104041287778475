<template>
  <v-card flat>
    <v-card-text>
      <h3>Zoom setting</h3>
      <div class="row" v-if="!setting.display_zoom">
        <div class="col-8 float-right">
          <div>


            <div class="col-12 float-right">
              <v-text-field outlined dense v-model="setting.zoom_client_key"
                            label="Zoom API client key"></v-text-field>
            </div>
            <div class="col-12 float-right">
              <v-text-field outlined dense v-model="setting.zoom_client_secret"
                            label="Zoom API secret key"></v-text-field>
            </div>
          </div>
        </div>

      </div>
      <div class="row" v-else>
        <div class="col-12 text-center">
          <img src="media/misc/lock.png" alt="lock" height="200">
          <br>
          <a href="#" class="btn btn-primary" @click="removeZoomSetting()">Remove</a>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: ["setting"],
  name: "zoom-setting",
  data() {

  }, methods: {
    removeZoomSetting() {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            this.setting.zoom_client_key = null
            this.setting.zoom_client_secret = null
            this.$emit('update_setting')
          }
        }
      });


    }
  }
}
</script>

<style scoped>

</style>
