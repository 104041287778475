<template>
    <v-app>
        <v-dialog fullscreen persistent
                  v-model="dialog">
            <v-card>

              <v-toolbar dark>
                <v-card-title class="headline">
                  {{`${edit?'Update':'Add'} ID card template`}}
                  <hr>
                </v-card-title>

                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-spacer></v-spacer>
                  <v-btn icon  @click="closeDialog">
                    <i class="fas fa-times"></i>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <v-card-text>
                <div class="row">
                  <div class="col-12 col-md-2">
                    <v-text-field
                        v-model="template.file_name"
                        outlined
                        dense
                        :error="$v.template.file_name.$error"
                        label="Title"
                    >
                    </v-text-field>
                    <span class="text-danger" v-if="$v.template.file_name.$error"
                    >This information is required</span
                    >
                  </div>

                  <div class="col-12 col-md-2">
                    <v-file-input
                        @change="previewBgImage"
                        v-model="template.bg_image"
                        label="Background image"
                        prepend-inner-icon="mdi-camera"
                        prepend-icon=""
                        @click:clear="previewImage = null"
                        accept="image/*"
                        outlined
                        :error="$v.template.bg_image.$error"
                        dense
                    ></v-file-input>
                    <span class="text-danger" v-if="$v.template.bg_image.$error"
                    >This information is required</span
                    >
                  </div>

                  <div class="col-12 col-md-2">
                    <v-select
                        @change="changeProgram"
                        :items="programs"
                        v-model="programDetail"
                        outlined
                        item-text="title"
                        return-object
                        dense
                        label="Program"
                    >
                    </v-select>
                  </div>

                  <div class="col-12 col-md-2">
                    <v-text-field
                        v-model="template.width"
                        @blur="changeSizeValue"
                        type="number"
                        label="ID card width"

                        outlined
                        dense
                    >
                    </v-text-field>
                    <span class="text-danger">** Please width millimeter size should be multiply by 2</span>
                  </div>

                  <div class="col-12 col-md-2">
                    <v-text-field
                        v-model="template.height"
                        @blur="changeSizeValue"
                        type="number"
                        label="ID card height"
                        color="primary"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <span class="text-danger">** Please height millimeter size should be multiply by 2</span>
                  </div>

                  <div class="col-12 col-md-2">
                    <v-text-field
                        v-model="template.top"
                        type="number"
                        label="Top"
                        color="primary"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <span class="text-danger">**This should be is millimeter size</span>
                  </div>

                  <div class="col-12 col-md-2">
                    <v-text-field
                        v-model="template.bottom"
                        type="number"
                        label="Bottom"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <span class="text-danger">**This should be is millimeter size</span>
                  </div>

                  <div class="col-12 col-md-2">
                    <v-text-field
                        v-model="template.left"
                        type="number"
                        label="Left"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <span class="text-danger">**This field should be millimeter size</span>
                  </div>

                  <div class="col-12 col-md-2">
                    <v-text-field
                        v-model="template.right"
                        type="number"
                        label="Right"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <span class="text-danger">**This field should be millimeter size</span>
                  </div>

                  <div class="col-12 col-md-2">
                    <v-text-field
                        v-model="template.gap_bottom"
                        type="number"
                        label="Gap bottom"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <span class="text-danger">**This field should be pixel size</span>
                  </div>

                  <div class="col-12 col-md-2">
                    <v-text-field
                        v-model="template.gap_right"
                        type="number"
                        label="Gap right"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <span class="text-danger">**This field should be pixel size</span>
                  </div>

                  <div class="col-12 col-md-2">
                    <v-select
                        v-model="settings.orientation"
                        label="Select an orientation"
                        :items="orientationTypes"
                        item-text="name"
                        item-value="value"
                        outlined
                        dense
                    >
                    </v-select>
                    <span class="text-danger">**Orientation for print</span>
                  </div>

                  <div class="col-12 col-md-2">
                    <v-select
                        v-model="settings.template_for"
                        label="Select a template for"
                        :items="userTypes"
                        item-text="name"
                        item-value="value"
                        outlined
                        dense
                    >
                    </v-select>
                  </div>

                  <div class="col-12 col-md-2">
                    <v-switch
                        v-model="template.is_front_view"
                        @change="changeIsFrontViewDefault"
                        label="Is front view"
                        outlined
                        dense
                    >
                    </v-switch>
                  </div>

                  <div class="col-12 col-md-2">
                    <v-switch
                        v-model="template.is_default"
                        label="Is Default"
                        outlined
                        dense
                    >
                    </v-switch>
                  </div>

                  <div class="col-12 col-md-2">
                    <v-switch
                        v-model="template.is_active"
                        label="Status"
                        outlined
                        dense
                    >
                    </v-switch>
                  </div>
                </div>
                <v-row>
                  <label class="font-size-lg">Options</label>
                  <v-col cols="12" md="2">
                    <v-checkbox
                        v-model="selected_options"
                        label="Blood group"
                        value="blood_group"
                        hide-details
                    ></v-checkbox>

                  </v-col>

                  <v-col cols="12" md="2">
                    <v-checkbox
                        v-model="selected_options"
                        label="Job position"
                        value="job_position"
                        hide-details
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-checkbox
                        v-model="selected_options"
                        label="Mobile No"
                        value="mobile_no"
                        hide-details
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-checkbox
                        v-model="selected_options"
                        label="Address"
                        value="address"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-checkbox
                        v-model="selected_options"
                        label="Bar code"
                        value="bar_code"
                        hide-details
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-checkbox
                        v-model="selected_options"
                        label="Signature"
                        value="principal_sign"
                        hide-details
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-checkbox
                        v-model="selected_options"
                        label="Program name"
                        value="program_name"
                        hide-details
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-checkbox
                        v-model="selected_options"
                        label="Grade"
                        value="grade"
                        hide-details
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-checkbox
                        v-model="selected_options"
                        label="Vaildity date"
                        value="vaildity_date"
                        hide-details
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-checkbox
                        v-model="selected_options"
                        label="Company name"
                        value="company_name"
                        hide-details
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-checkbox
                        v-model="selected_options"
                        label="Company phone"
                        value="company_phone"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-checkbox
                        v-model="selected_options"
                        label="Company address"
                        value="company_address"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-checkbox
                        v-model="selected_options"
                        label="Company website"
                        value="company_website"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-checkbox
                        v-model="selected_options"
                        label="Company email"
                        value="company_email"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-checkbox
                        v-model="selected_options"
                        label="Qr code"
                        value="qr_code"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-checkbox
                        v-model="selected_options"
                        label="Free text one"
                        value="free_text_one"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-checkbox
                        v-model="selected_options"
                        label="Free text two"
                        value="free_text_two"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-checkbox
                        v-model="selected_options"
                        label="Free text three"
                        value="free_text_three"
                    ></v-checkbox>
                  </v-col>

                </v-row>
                <v-row>
                  <v-col cols="12" md="9">
                      <v-row>
                        <!-- Student Photo Position (Top) -->
                        <v-col
                            cols="12" md="3"
                            v-if="id_card_fields.includes('student_photo')"
                        >
                          <label>
                            <strong>Student photo position (Top)</strong>
                          </label>
                          <v-slider
                              :max="size.top_max"
                              v-model="template.student_photo_position_top"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Student Photo Position (Left) -->
                        <v-col
                            cols="12" md="3"
                            v-if="id_card_fields.includes('student_photo')"
                        >
                          <label>
                            <strong>Student photo position (Left)</strong>
                          </label>
                          <v-slider
                              max="500"
                              v-model="template.student_photo_position_left"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Student Photo Width -->
                        <v-col
                            cols="12" md="3"
                            v-if="id_card_fields.includes('student_photo')"
                        >
                          <label>
                            <strong>Student photo position (Width)</strong>
                          </label>
                          <v-slider
                              max="500"
                              v-model="template.student_photo_width"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Student Photo Height -->
                        <v-col
                            cols="12" md="3"
                            v-if="id_card_fields.includes('student_photo')"
                        >
                          <label>
                            <strong>Student photo position (Height)</strong>
                          </label>
                          <v-slider
                              :max="size.top_max"
                              v-model="template.student_photo_height"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Student ID Position (Top) -->
                        <v-col
                            cols="12" md="3"
                            v-if="id_card_fields.includes('student_id')"
                        >
                          <label>
                            <strong>Student ID position (Top)</strong>
                          </label>
                          <v-slider
                              :max="size.top_max"
                              v-model="template.student_id_position_top"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Student ID Position (Left) -->
                        <v-col
                            cols="12" md="3"
                            v-if="id_card_fields.includes('student_id')"
                        >
                          <label>
                            <strong>Student ID position (Left)</strong>
                          </label>
                          <v-slider
                              :max="size.left_max"
                              v-model="template.student_id_position_left"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Student Name Position (Top) -->
                        <v-col
                            cols="12" md="3"
                            v-if="id_card_fields.includes('student_name')"
                        >
                          <label>
                            <strong>Student name position (Top)</strong>
                          </label>
                          <v-slider
                              :max="size.top_max"
                              v-model="template.student_name_position_top"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Student Name Position (Left) -->
                        <v-col
                            cols="12" md="3"
                            v-if="id_card_fields.includes('student_name')"
                        >
                          <label>
                            <strong>Student name position (Left)</strong>
                          </label>
                          <v-slider
                              :max="size.left_max"
                              v-model="template.student_name_position_left"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Program Name Position (Top) -->
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('program_name')"
                        >
                          <label>
                            <strong>Program name position (Top)</strong>
                          </label>
                          <v-slider
                              :max="size.top_max"
                              v-model="template.program_name_position_top"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Program Name Position (Left) -->
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('program_name')"
                        >
                          <label>
                            <strong>Program name position (Left)</strong>
                          </label>
                          <v-slider
                              :max="size.left_max"
                              v-model="template.program_name_position_left"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Grade Position (Top) -->
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('grade')"
                        >
                          <label>
                            <strong>Grade position (Top)</strong>
                          </label>
                          <v-slider
                              :max="size.top_max"
                              v-model="template.grade_position_top"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Grade Position (Left) -->
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('grade')"
                        >
                          <label>
                            <strong>Grade position (Left)</strong>
                          </label>
                          <v-slider
                              :max="size.left_max"
                              v-model="template.grade_position_left"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Parent Name Position (Top) -->
                        <v-col
                            cols="12" md="3"
                            v-if="id_card_fields.includes('parent_name')"
                        >
                          <label>
                            <strong>Parent name position (Top)</strong>
                          </label>
                          <v-slider
                              :max="size.top_max"
                              v-model="template.parent_name_position_top"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Parent Name Position (Left) -->
                        <v-col
                            cols="12" md="3"
                            v-if="id_card_fields.includes('parent_name')"
                        >
                          <label>
                            <strong>Parent name position (Left)</strong>
                          </label>
                          <v-slider
                              :max="size.top_max"
                              v-model="template.parent_name_position_left"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Date of Birth Position (Top) -->
                        <v-col
                            cols="12" md="3"
                            v-if="id_card_fields.includes('date_of_birth')"
                        >
                          <label>
                            <strong>Date of birth position (Top)</strong>
                          </label>
                          <v-slider
                              :max="size.top_max"
                              v-model="template.date_of_birth_position_top"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Date of Birth Position (Left) -->
                        <v-col
                            cols="12" md="3"
                            v-if="id_card_fields.includes('date_of_birth')"
                        >
                          <label>
                            <strong>Date of birth position (Left)</strong>
                          </label>
                          <v-slider
                              :max="size.top_max"
                              v-model="template.date_of_birth_position_left"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Academic Year Position (Top) -->
                        <v-col
                            cols="12" md="3"
                            v-if="id_card_fields.includes('academic_year')"
                        >
                          <label>
                            <strong>Academic year position (Top)</strong>
                          </label>
                          <v-slider
                              :max="size.top_max"
                              v-model="template.academic_year_position_top"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Academic Year Position (Left) -->
                        <v-col
                            cols="12" md="3"
                            v-if="id_card_fields.includes('academic_year')"
                        >
                          <label>
                            <strong>Academic year position (Left)</strong>
                          </label>
                          <v-slider
                              :max="size.left_max"
                              v-model="template.academic_year_position_left"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Address Position (Top) -->
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('address')"
                        >
                          <label>
                            <strong>Address position (Top)</strong>
                          </label>
                          <v-slider
                              :max="size.top_max"
                              v-model="template.address_position_top"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Address Position (Left) -->
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('address')"
                        >
                          <label>
                            <strong>Address position (Left)</strong>
                          </label>
                          <v-slider
                              :max="size.left_max"
                              v-model="template.address_position_left"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Mobile Number Position (Top) -->
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('mobile_no')"
                        >
                          <label>
                            <strong>Mobile no position (Top)</strong>
                          </label>
                          <v-slider
                              :max="size.top_max"
                              v-model="template.mobile_no_position_top"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Mobile Number Position (Left) -->
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('mobile_no')"
                        >
                          <label>
                            <strong>Mobile no position (Left)</strong>
                          </label>
                          <v-slider
                              :max="size.left_max"
                              v-model="template.mobile_no_position_left"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Registration Number Position (Top) -->
                        <v-col
                            cols="12" md="3"
                            v-if="id_card_fields.includes('registration_no')"
                        >
                          <label>
                            <strong>Registration no position (Top)</strong>
                          </label>
                          <v-slider
                              :max="size.top_max"
                              v-model="template.registration_no_position_top"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Registration Number Position (Left) -->
                        <v-col
                            cols="12" md="3"
                            v-if="id_card_fields.includes('registration_no')"
                        >
                          <label>
                            <strong>Registration no position (Left)</strong>
                          </label>
                          <v-slider
                              :max="size.top_max"
                              v-model="template.registration_no_position_left"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Blood Group Position (Top) -->
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('blood_group')"
                        >
                          <label>
                            <strong>Blood group position (Top)</strong>
                          </label>
                          <v-slider
                              :max="size.top_max"
                              v-model="template.blood_group_position_top"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Blood Group Position (Left) -->
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('blood_group')"
                        >
                          <label>
                            <strong>Blood group position (Left)</strong>
                          </label>
                          <v-slider
                              :max="size.left_max"
                              v-model="template.blood_group_position_left"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Job Position Position (Top) -->
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('job_position')"
                        >
                          <label>
                            <strong>Job position (Top)</strong>
                          </label>
                          <v-slider
                              :max="size.top_max"
                              v-model="template.job_position_top"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Job Position Position (Left) -->
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('job_position')"
                        >
                          <label>
                            <strong>Job position (Left)</strong>
                          </label>
                          <v-slider
                              :max="size.left_max"
                              v-model="template.job_position_left"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Barcode Position (Top) -->
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('bar_code')"
                        >
                          <label>
                            <strong>Barcode position (Top)</strong>
                          </label>
                          <v-slider
                              :max="size.top_max"
                              v-model="template.barcode_position_top"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Barcode Position (Left) -->
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('bar_code')"
                        >
                          <label>
                            <strong>Barcode position (Left)</strong>
                          </label>
                          {{size.left_max}}
                          <v-slider
                              :max="size.left_max"
                              v-model="template.barcode_position_left"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Barcode Width -->
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('bar_code')"
                        >
                          <label>
                            <strong>Barcode width</strong>
                          </label>
                          <v-slider
                              :max="size.top_max"
                              v-model="settings.barcode_width"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Barcode Height -->
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('bar_code')"
                        >
                          <label>
                            <strong>Barcode height</strong>
                          </label>
                          <v-slider
                              :max="size.left_max"
                              v-model="settings.barcode_height"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Principal Sign Position (Top) -->
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('principal_sign')"
                        >
                          <label>
                            <strong>Principal sign position (Top)</strong>
                          </label>
                          <v-slider
                              :max="size.top_max"
                              v-model="template.principal_sign_position_top"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Principal Sign Position (Left) -->
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('principal_sign')"
                        >
                          <label>
                            <strong>Principal sign position (Left)</strong>
                          </label>
                          <v-slider
                              :max="size.left_max"
                              v-model="template.principal_sign_position_left"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Principal Sign Width -->
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('principal_sign')"
                        >
                          <label>
                            <strong>Principal sign width</strong>
                          </label>
                          <v-slider
                              :max="size.left_max"
                              v-model="settings.principal_sign_width"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Principal Sign Height -->
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('principal_sign')"
                        >
                          <label>
                            <strong>Principal sign height</strong>
                          </label>
                          <v-slider
                              :max="size.top_max"
                              v-model="settings.principal_sign_height"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Validity Date Position (Top) -->
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('vaildity_date')"
                        >
                          <label>
                            <strong>Validity date position (Top)</strong>
                          </label>
                          <v-slider
                              :max="size.top_max"
                              v-model="template.vaildity_date_position_top"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Validity Date Position (Left) -->
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('vaildity_date')"
                        >
                          <label>
                            <strong>Validity date position (Left)</strong>
                          </label>
                          <v-slider
                              :max="size.left_max"
                              v-model="template.vaildity_date_position_left"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Company Name Position -->
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('company_name')"
                        >
                          <label><strong>Company name position (Top)</strong></label>
                          <v-slider
                              :max="size.top_max"
                              v-model="template.company_name_position_top"
                              ticks
                          ></v-slider>
                        </v-col>
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('company_name')"
                        >
                          <label><strong>Company name position (Left)</strong></label>
                          <v-slider
                              :max="size.left_max"
                              v-model="template.company_name_position_left"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Company Phone Position -->
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('company_phone')"
                        >
                          <label><strong>Company phone position (Top)</strong></label>
                          <v-slider
                              :max="size.top_max"
                              v-model="template.company_phone_position_top"
                              ticks
                          ></v-slider>
                        </v-col>
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('company_phone')"
                        >
                          <label><strong>Company phone position (Left)</strong></label>
                          <v-slider
                              :max="size.left_max"
                              v-model="template.company_phone_position_left"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Company Address Position -->
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('company_address')"
                        >
                          <label><strong>Company address position (Top)</strong></label>
                          <v-slider
                              :max="size.top_max"
                              v-model="template.company_address_position_top"
                              ticks
                          ></v-slider>
                        </v-col>
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('company_address')"
                        >
                          <label><strong>Company address position (Left)</strong></label>
                          <v-slider
                              :max="size.left_max"
                              v-model="template.company_address_position_left"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Company Website Position -->
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('company_website')"
                        >
                          <label><strong>Company website position (Top)</strong></label>
                          <v-slider
                              :max="size.top_max"
                              v-model="template.company_website_position_top"
                              ticks
                          ></v-slider>
                        </v-col>
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('company_website')"
                        >
                          <label><strong>Company website position (Left)</strong></label>
                          <v-slider
                              :max="size.left_max"
                              v-model="template.company_website_position_left"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Company Email Position -->
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('company_email')"
                        >
                          <label><strong>Company email position (Top)</strong></label>
                          <v-slider
                              :max="size.top_max"
                              v-model="template.company_email_position_top"
                              ticks
                          ></v-slider>
                        </v-col>
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('company_email')"
                        >
                          <label><strong>Company email position (Left)</strong></label>
                          <v-slider
                              :max="size.left_max"
                              v-model="template.company_email_position_left"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- QR Code Position -->
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('qr_code')"
                        >
                          <label><strong>QR code position (Top)</strong></label>
                          <v-slider
                              :max="size.top_max"
                              v-model="template.qr_code_position_top"
                              ticks
                          ></v-slider>
                        </v-col>
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('qr_code')"
                        >
                          <label><strong>QR code position (Left)</strong></label>
                          <v-slider
                              :max="size.left_max"
                              v-model="template.qr_code_position_left"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- QR Code size -->
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('qr_code')"
                        >
                          <label><strong>QR code (Width)</strong></label>
                          <v-slider
                              :max="size.top_max"
                              v-model="settings.qr_code_width"
                              ticks
                          ></v-slider>
                        </v-col>
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('qr_code')"
                        >
                          <label><strong>QR code (Height)</strong></label>
                          <v-slider
                              :max="size.left_max"
                              v-model="settings.qr_code_height"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Free Text One Position -->
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('free_text_one')"
                        >
                          <label><strong>Free text one position (Top)</strong></label>
                          <v-slider
                              :max="size.top_max"
                              v-model="template.free_text_one_position_top"
                              ticks
                          ></v-slider>
                        </v-col>
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('free_text_one')"
                        >
                          <label><strong>Free text one position (Left)</strong></label>
                          <v-slider
                              :max="size.left_max"
                              v-model="template.free_text_one_position_left"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Free Text Two Position -->
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('free_text_two')"
                        >
                          <label><strong>Free text two position (Top)</strong></label>
                          <v-slider
                              :max="size.top_max"
                              v-model="template.free_text_two_position_top"
                              ticks
                          ></v-slider>
                        </v-col>
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('free_text_two')"
                        >
                          <label><strong>Free text two position (Left)</strong></label>
                          <v-slider
                              :max="size.left_max"
                              v-model="template.free_text_two_position_left"
                              ticks
                          ></v-slider>
                        </v-col>

                        <!-- Free Text Three Position -->
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('free_text_three')"
                        >
                          <label><strong>Free text three position (Top)</strong></label>
                          <v-slider
                              :max="size.top_max"
                              v-model="template.free_text_three_position_top"
                              ticks
                          ></v-slider>
                        </v-col>
                        <v-col
                            cols="12" md="3"
                            v-if="selected_options.includes('free_text_three')"
                        >
                          <label><strong>Free text three position (Left)</strong></label>
                          <v-slider
                              :max="size.left_max"
                              v-model="template.free_text_three_position_left"
                              ticks
                          ></v-slider>
                        </v-col>
                      </v-row>
                  </v-col>

                  <v-col cols="12" md="3" class="align-center" >
                    <v-card class="outer-border bgimg"
                        v-bind:style="{
                        width: template.width+'mm',
                        height: template.height+'mm',
                        maxWidth: template.width+'mm',
                        maxHeight: template.height+'mm',
                        'background-image': 'url(' + previewImage + ')',
                        'border': '1px solid black'
                      }"
                    >
                      <div
                          v-if="template.type == 'id_card'"
                      >
                        <div style="position: absolute; width: 100%">
                          <!-- Student Photo -->
                          <div
                              v-if="id_card_fields.includes('student_photo')"
                              class="fs-15"
                              style="position: absolute"
                              v-bind:style="{
                                top: template.student_photo_position_top + 'mm',
                                left: template.student_photo_position_left + 'mm',
                              }"
                          >
                            <img src="/media/users/user.png" alt="image" :width="`${template.student_photo_width}mm`" :height="`${template.student_photo_height}mm`" />
                          </div>

                          <!-- Student ID -->
                          <div
                              v-if="id_card_fields.includes('student_id')"
                              class="fs-15"
                              style="position: absolute"
                              v-bind:style="{
                                top: template.student_id_position_top + 'mm',
                                left: template.student_id_position_left + 'mm',
                              }"
                          >
                            Student ID
                          </div>

                          <!-- Student Name -->
                          <div
                              v-if="id_card_fields.includes('student_name')"
                              class="fs-15"
                              style="position: absolute"
                              v-bind:style="{
                                top: template.student_name_position_top + 'mm',
                                left: template.student_name_position_left + 'mm',
                              }"
                          >
                            Student name
                          </div>

                          <!-- Program Name -->
                          <div
                              v-if="selected_options.includes('program_name')"
                              class="fs-15"
                              style="position: absolute"
                              v-bind:style="{
                                top: template.program_name_position_top + 'mm',
                                left: template.program_name_position_left + 'mm',
                              }"
                          >
                            Program name
                          </div>

                          <!-- Parent Name -->
                          <div
                              v-if="id_card_fields.includes('parent_name')"
                              class="fs-15"
                              style="position: absolute"
                              v-bind:style="{
                                top: template.parent_name_position_top + 'mm',
                                left: template.parent_name_position_left + 'mm',
                              }"
                          >
                            Parent name
                          </div>

                          <!-- Date of Birth -->
                          <div
                              v-if="id_card_fields.includes('date_of_birth')"
                              class="fs-15"
                              style="position: absolute"
                              v-bind:style="{
                                top: template.date_of_birth_position_top + 'mm',
                                left: template.date_of_birth_position_left + 'mm',
                              }"
                          >
                            Date of birth
                          </div>

                          <!-- Academic Year -->
                          <div
                              v-if="id_card_fields.includes('academic_year')"
                              class="fs-15"
                              style="position: absolute"
                              v-bind:style="{
                                top: template.academic_year_position_top + 'mm',
                                left: template.academic_year_position_left + 'mm',
                              }"
                          >
                            Academic year
                          </div>

                          <!-- Address -->
                          <div
                              v-if="selected_options.includes('address')"
                              class="fs-15"
                              style="position: absolute"
                              v-bind:style="{
                                top: template.address_position_top + 'mm',
                                left: template.address_position_left + 'mm',
                              }"
                          >
                            Address
                          </div>

                          <!-- Registration Number -->
                          <div
                              v-if="id_card_fields.includes('registration_no')"
                              class="fs-15"
                              style="position: absolute"
                              v-bind:style="{
                                top: template.registration_no_position_top + 'mm',
                                left: template.registration_no_position_left + 'mm',
                              }"
                          >
                            Registration no
                          </div>

                          <!-- Job Position -->
                          <div
                              v-if="selected_options.includes('job_position')"
                              class="fs-15"
                              style="position: absolute"
                              v-bind:style="{
                                top: template.job_position_top + 'mm',
                                left: template.job_position_left + 'mm',
                              }"
                          >
                            Job position
                          </div>

                          <!-- Blood Group -->
                          <div
                              v-if="selected_options.includes('blood_group')"
                              class="fs-15"
                              style="position: absolute"
                              v-bind:style="{
                                top: template.blood_group_position_top + 'mm',
                                left: template.blood_group_position_left + 'mm',
                              }"
                          >
                            Blood group
                          </div>

                          <!-- Grade -->
                          <div
                              v-if="selected_options.includes('grade')"
                              class="fs-15"
                              style="position: absolute"
                              v-bind:style="{
                                top: template.grade_position_top + 'mm',
                                left: template.grade_position_left + 'mm',
                              }"
                          >
                            Grade
                          </div>

                          <!-- Mobile Number -->
                          <div
                              v-if="selected_options.includes('mobile_no')"
                              class="fs-15"
                              style="position: absolute"
                              v-bind:style="{
                                top: template.mobile_no_position_top + 'mm',
                                left: template.mobile_no_position_left + 'mm',
                              }"
                          >
                            Mobile no.
                          </div>

                          <!-- Barcode -->
                          <div
                              v-if="selected_options.includes('bar_code')"
                              class="fs-15"
                              style="position: absolute"
                              v-bind:style="{
                                top: template.barcode_position_top + 'mm',
                                left: template.barcode_position_left + 'mm',
                              }"
                          >
                            <img v-if="barcode" alt="barcode" :src="'data:image/png;base64, '+barcode.barcode" :width="settings.barcode_width" :height="settings.barcode_height" />
                            <p class="text-center" v-if="barcode">{{barcode.barcode_no}}</p>
                          </div>

                          <!-- Principal Sign -->
                          <div
                              v-if="selected_options.includes('principal_sign')"
                              class="fs-15"
                              style="position: absolute"
                              v-bind:style="{
                                top: template.principal_sign_position_top + 'mm',
                                left: template.principal_sign_position_left + 'mm',
                              }"
                          >
                            <img :src="signature" alt="principal_sign" :width="settings.principal_sign_width" :height="settings.principal_sign_height" />
                          </div>

                          <!-- Validity Date -->
                          <div
                              v-if="selected_options.includes('vaildity_date')"
                              class="fs-15"
                              style="position: absolute"
                              v-bind:style="{
                                top: template.vaildity_date_position_top + 'mm',
                                left: template.vaildity_date_position_left + 'mm',
                              }"
                          >
                            Vaildity date
                          </div>

                          <!-- Company Name -->
                          <div
                              v-if="selected_options.includes('company_name')"
                              class="fs-15"
                              style="position: absolute"
                              v-bind:style="{
                                top: template.company_name_position_top + 'mm',
                                left: template.company_name_position_left + 'mm',
                              }"
                          >
                            Company name
                          </div>

                          <!-- Company Phone -->
                          <div
                              v-if="selected_options.includes('company_phone')"
                              class="fs-15"
                              style="position: absolute"
                              v-bind:style="{
                                top: template.company_phone_position_top + 'mm',
                                left: template.company_phone_position_left + 'mm',
                              }"
                          >
                            Company phone
                          </div>

                          <!-- Company Address -->
                          <div
                              v-if="selected_options.includes('company_address')"
                              class="fs-15"
                              style="position: absolute"
                              v-bind:style="{
                                top: template.company_address_position_top + 'mm',
                                left: template.company_address_position_left + 'mm',
                              }"
                          >
                            Company address
                          </div>

                          <!-- Company Website -->
                          <div
                              v-if="selected_options.includes('company_website')"
                              class="fs-15"
                              style="position: absolute"
                              v-bind:style="{
                                top: template.company_website_position_top + 'mm',
                                left: template.company_website_position_left + 'mm',
                              }"
                          >
                            Company website
                          </div>

                          <!-- Company Email -->
                          <div
                              v-if="selected_options.includes('company_email')"
                              class="fs-15"
                              style="position: absolute"
                              v-bind:style="{
                                top: template.company_email_position_top + 'mm',
                                left: template.company_email_position_left + 'mm',
                              }"
                          >
                            Company email
                          </div>

                          <!-- QR Code -->
                          <div
                              v-if="selected_options.includes('qr_code')"
                              class="fs-15"
                              style="position: absolute"
                              v-bind:style="{
                                top: template.qr_code_position_top + 'mm',
                                left: template.qr_code_position_left + 'mm',
                              }"
                          >
                            <img alt="QR code" src="/media/users/qrcode.png" :width="`${settings.qr_code_width}mm`" :height="`${settings.qr_code_height}mm`"/>
                          </div>

                          <!-- Free Text One -->
                          <div
                              v-if="selected_options.includes('free_text_one')"
                              class="fs-15"
                              style="position: absolute"
                              v-bind:style="{
                                top: template.free_text_one_position_top + 'mm',
                                left: template.free_text_one_position_left + 'mm',
                              }"
                          >
                            Free text one
                          </div>

                          <!-- Free Text Two -->
                          <div
                              v-if="selected_options.includes('free_text_two')"
                              class="fs-15"
                              style="position: absolute"
                              v-bind:style="{
                                top: template.free_text_two_position_top + 'mm',
                                left: template.free_text_two_position_left + 'mm',
                              }"
                          >
                            Free text two
                          </div>

                          <!-- Free Text Three -->
                          <div
                              v-if="selected_options.includes('free_text_three')"
                              class="fs-15"
                              style="position: absolute"
                              v-bind:style="{
                                top: template.free_text_three_position_top + 'mm',
                                left: template.free_text_three_position_left + 'mm',
                              }"
                          >
                            Free text three
                          </div>

                        </div>
                      </div>
                      <span class="certification">
                        {{ template.file_name }}
                      </span>
                    </v-card>
                  </v-col>

                </v-row>

              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    depressed
                    @click="closeDialog"
                    class="text-gray btn btn-standard"
                >
                  Cancel
                </v-btn>
                <v-btn
                    v-if="checkIsAccessible('template', 'create')"
                    depressed
                    :loading="isBusy"
                    @click="createAndUpdate"
                    class="text-white ml-2 btn btn-primary"
                >
                  {{edit ? "Update" : "Save"}}
                </v-btn>
              </v-card-actions>

            </v-card>

        </v-dialog>
    </v-app>
</template>
<script>
import {required, requiredIf} from "vuelidate/lib/validators";
import ProgramService from "@/core/services/level/program/ProgramService";
import TemplatesService from "@/core/services/templates/TemplatesService";

const programService = new ProgramService();
const templatesService = new TemplatesService();
export default {
  name: "template-create-and-update",
  validations: {
      template: {
          file_name: { required },
          bg_image: { required: requiredIf(function () {
              return !this.edit;
            })
          },
      }
  },
  data() {
      return {
          dialog: false,
          menu: false,
          isBusy: false,
          menu2: false,
          edit: false,
          selected_options: [],
          orientationTypes: [
            {
              name: "Portrait",
              value: "portrait",
            },
            {
              name: "Landscape",
              value: "landscape",
            }
          ],
          levels: [],
          programs: [],
          academicYears: [],
          gradesLevels: [],
          id_card_fields: [],
          setting: null,
          previewImage: null,
          programDetail: null,
          signature: null,
          barcode: null,
          settings: {
              options: [],
              template_for: 'student',
              principal_sign_width: 50,
              principal_sign_height: 50,
              barcode_width: 50,
              barcode_height: 50,
              qr_code_width: 50,
              qr_code_height: 50,
          },
          userTypes: [
            { name: 'Teacher', value: 'teacher' },
            { name: 'Student', value: 'student' },
            { name: 'Staff', value: 'staff' },
          ],
          template: {
            file_name: null,
            path: null,
            type: 'id_card',
            bg_image: null,
            is_default: true,
            is_front_view: true,
            is_active: true,
            program_id: null,
            width: 112,
            height: 176,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            gap_bottom: 0,
            gap_right: 0,
            student_photo_position: "",
            student_photo_position_top: 0,
            student_photo_position_left: 0,
            student_photo_width: 50,
            student_photo_height: 50,
            student_id_position: "",
            student_id_position_top: 0,
            student_id_position_left: 0,
            student_name_position_top: 0,
            student_name_position_left: 0,
            program_name_position: "",
            program_name_position_top: 0,
            program_name_position_left: 0,
            parent_name_position: "",
            parent_name_position_top: 0,
            parent_name_position_left: 0,
            mobile_no_position: "",
            mobile_no_position_top: 0,
            mobile_no_position_left: 0,
            barcode_position: "",
            barcode_position_top: 0,
            barcode_position_left: 0,
            address_position: "",
            address_position_top: 0,
            address_position_left: 0,
            registration_no_position: "",
            registration_no_position_top: 0,
            registration_no_position_left: 0,
            date_of_birth_position: "",
            date_of_birth_position_top: 0,
            date_of_birth_position_left: 0,
            blood_group_position: "",
            blood_group_position_top: 0,
            blood_group_position_left: 0,
            job_position: "",
            job_position_top: 0,
            job_position_left: 0,
            principal_sign_position: "",
            principal_sign_position_top: 0,
            principal_sign_position_left: 0,
            vaildity_date_position: "",
            vaildity_date_position_top: 0,
            vaildity_date_position_left: 0,
            company_name_position_top: 0,
            company_name_position_left: 0,
            company_phone_position_top: 0,
            company_phone_position_left: 0,
            company_address_position_top: 0,
            company_address_position_left: 0,
            company_website_position_top: 0,
            company_website_position_left: 0,
            company_email_position_top: 0,
            company_email_position_left: 0,
            qr_code_position_top: 0,
            qr_code_position_left: 0,
            free_text_one_position_top: 0,
            free_text_one_position_left: 0,
            free_text_two_position_top: 0,
            free_text_two_position_left: 0,
            free_text_three_position_top: 0,
            free_text_three_position_left: 0,
          },
          size: {
            left_max: 100,
            top_max: 100,
          },
      };
  },
  computed: {

      programId() {
          return this.$route.params.id ? this.$route.params.id : false;
      }
  },

  watch: {
      settings(newValue, oldValue) {
          deep: true,
              handler()
          {
              this.template.settings = newValue;
          }
      }
  },
  methods: {
    changeIsFrontViewDefault() {
      if(!this.programDetail && !this.template.is_front_view) {
        this.id_card_fields = [];
      }
      if(!this.programDetail && this.template.is_front_view) {
        this.id_card_fields = ['student_photo', 'student_name', 'student_id'];
      }
    },

    changeProgram() {
      this.id_card_fields = this.programDetail.id_card_fields || [];
      this.template.program_id = this.programDetail.id;
    },

    changeSizeValue() {
      if(this.template.width) {
        this.size.left_max = this.template.width+"mm";
      }
      if(this.template.height) {
        this.size.top_max = this.template.height+"mm";
      }
    },

    getProgram() {
        programService.all().then(response => {
            this.programs = response.data
        });
    },

    showModal(template = {}) {
      this.signature = this.$store.getters.getPrincipalSign;
      this.barcode = this.$store.getters.getBarcode;
      this.resetForm();
      if (template.id) {
        this.edit = true
        this.getTemplateDetail(template.id);
      } else {
          this.edit = false;
      }
      this.getProgram()
      this.dialog = true;
      if(this.template.is_default && !this.programDetail) {
        this.id_card_fields = ['student_photo', 'student_name', 'student_id'];
      }
    },

    getTemplateDetail(templateId) {
      templatesService.show(templateId).then((response) => {
        this.template = response.data.templates;
        if (this.template && this.template.id) {
          this.programDetail = this.template.program;
          if (this.programDetail) {
            this.changeProgram();
          }
          if(this.template.width == null) {
            this.template.width = 100;
          }
          if(this.template.height == null) {
            this.template.height = 100;
          }
          this.previewImage = this.template.image_path["real"];
          this.selected_options = this.template.settings.options || [];
          this.settings.orientation = this.template.settings.orientation;
          this.changeSizeValue()
        }
      })
      .catch(() => {
        this.loading = false;
      })
      .finally(() => {
        this.loading = false;
      });
    },

    closeDialog() {
      this.dialog = false;
      this.resetForm()
    },

    generateFd() {
      let fd = new FormData();
      for (let key in this.template) {
        if (
            key == "bg_image" &&
            this.template["bg_image"] &&
            this.template["bg_image"] != null
        ) {
          if (typeof this.template["bg_image"] == "object") {
            fd.append("bg_image", this.template[key]);
          }
        } else if (key == "is_default" || key == "is_active" || key == "is_front_view") {
          fd.append(key, this.template[key] ? 1 : 0);
        } else {
          if (key != "id" && this.template[key]) {
            fd.append(key, this.template[key]);
          }
        }
      }
      return fd;
    },

    previewBgImage() {
      this.previewImage = URL.createObjectURL(this.template.bg_image)
    },

    createAndUpdate() {
        // this.template.academic_year_id = this.setting.academic_year_id;
        delete this.template.image_path;
        delete this.template.program;
        this.template.settings  = {};
        this.template.settings = this.settings;
        this.template.settings.options = this.selected_options;
        this.template.settings = JSON.stringify(this.template.settings);
        this.$v.$touch();
        if (this.$v.template.$error) {
            setTimeout(() => {
                this.$v.$reset();
            }, 3000);
        } else {
            this.customizeTemplateData();
            let fd = this.generateFd();
            if (this.edit) {
                this.updateTemplate(fd);
            } else {
                this.createTemplate(fd);
            }
        }
    },

    createTemplate(fd) {
        this.isBusy = true;
        templatesService.store(fd).then(response => {
            this.closeDialog()
            this.isBusy = false;
            this.$snotify.success("Information added");
            this.$emit("refresh");
        });
    },

    updateTemplate(fd) {
        this.isBusy = true;
        templatesService.update(this.template.id, fd).then(response => {
            this.closeDialog()
            this.isBusy = false;
            this.$snotify.success("Information updated");
            this.$emit("refresh");
        });
    },
    customizeTemplateData() {
      if (this.id_card_fields.includes("student_photo")) {
        this.template.student_photo_position =
            this.template.student_photo_position_top +
            "," +
            this.template.student_photo_position_left;
        this.template.student_photo_size =
            this.template.student_photo_width +
            "," +
            this.template.student_photo_height;
      }
      if (this.id_card_fields.includes("student_id")) {
        this.template.student_id_position =
            this.template.student_id_position_top +
            "," +
            this.template.student_id_position_left;
      }
      if (this.id_card_fields.includes("student_name")) {
        this.template.student_name_position =
            this.template.student_name_position_top +
            "," +
            this.template.student_name_position_left;
      }

      if (this.selected_options.includes("program_name")) {
        this.template.program_name_position =
            this.template.program_name_position_top +
            "," +
            this.template.program_name_position_left;
      }

      if (this.selected_options.includes("grade")) {
        this.template.grade_position =
            this.template.grade_position_top +
            "," +
            this.template.grade_position_left;
      }

      if (this.id_card_fields.includes("parent_name")) {
        this.template.parent_name_position =
            this.template.parent_name_position_top +
            "," +
            this.template.parent_name_position_left;
      }

      if (this.id_card_fields.includes("date_of_birth")) {
        this.template.date_of_birth_position =
            this.template.date_of_birth_position_top +
            "," +
            this.template.date_of_birth_position_left;
      }
      if (this.id_card_fields.includes("academic_year")) {
        this.template.academic_year_position =
            this.template.academic_year_position_top +
            "," +
            this.template.academic_year_position_left;
      }
      if (this.selected_options.includes("address")) {
        this.template.address_position =
            this.template.address_position_top +
            "," +
            this.template.address_position_left;
      }
      if (this.id_card_fields.includes("registration_no")) {
        this.template.registration_no_position =
            this.template.registration_no_position_top +
            "," +
            this.template.registration_no_position_left;
      }
      if (this.selected_options.includes("mobile_no")) {
        this.template.mobile_no_position =
            this.template.mobile_no_position_top +
            "," +
            this.template.mobile_no_position_left;
      }
      if (this.selected_options.includes("principal_sign")) {
        this.template.principal_sign_position =
            this.template.principal_sign_position_top +
            "," +
            this.template.principal_sign_position_left;
      }
      if (this.selected_options.includes("bar_code")) {
        this.template.barcode_position =
            this.template.barcode_position_top +
            "," +
            this.template.barcode_position_left;
      }
      if (this.selected_options.includes("vaildity_date")) {
        this.template.vaildity_date_position =
            this.template.vaildity_date_position_top +
            "," +
            this.template.vaildity_date_position_left;
      }
      if (this.selected_options.includes("blood_group")) {
        this.template.blood_group_position =
            this.template.blood_group_position_top +
            "," +
            this.template.blood_group_position_left;
      }
      if (this.selected_options.includes("job_position")) {
        this.template.job_position =
            this.template.job_position_top +
            "," +
            this.template.job_position_left;
      }
      if (this.selected_options.includes("company_name")) {
        this.template.company_name_position =
            this.template.company_name_position_top +
            "," +
            this.template.company_name_position_left;
      }
      if (this.selected_options.includes("company_phone")) {
        this.template.company_phone_position =
            this.template.company_phone_position_top +
            "," +
            this.template.company_phone_position_left;
      }

      if (this.selected_options.includes("company_address")) {
        this.template.company_address_position =
            this.template.company_address_position_top +
            "," +
            this.template.company_address_position_left;
      }

      if (this.selected_options.includes("company_website")) {
        this.template.company_website_position =
            this.template.company_website_position_top +
            "," +
            this.template.company_website_position_left;
      }

      if (this.selected_options.includes("company_email")) {
        this.template.company_email_position =
            this.template.company_email_position_top +
            "," +
            this.template.company_email_position_left;
      }

      if (this.selected_options.includes("qr_code")) {
        this.template.qr_code_position =
            this.template.qr_code_position_top +
            "," +
            this.template.qr_code_position_left;
      }

      if (this.selected_options.includes("free_text_one")) {
        this.template.free_text_one_position =
            this.template.free_text_one_position_top +
            "," +
            this.template.free_text_one_position_left;
      }

      if (this.selected_options.includes("free_text_two")) {
        this.template.free_text_two_position =
            this.template.free_text_two_position_top +
            "," +
            this.template.free_text_two_position_left;
      }

      if (this.selected_options.includes("free_text_three")) {
        this.template.free_text_three_position =
            this.template.free_text_three_position_top +
            "," +
            this.template.free_text_three_position_left;
      }
      this.template.settings = this.settings;
      this.template.settings = JSON.stringify(this.template.settings);
    },
    resetForm() {
      this.edit = false
      this.$v.$reset()
      this.previewImage = null;
      this.programDetail = null;
      this.id_card_fields = [];
      this.template = {
        file_name: null,
        path: null,
        type: 'id_card',
        bg_image: null,
        is_default: true,
        is_front_view: true,
        is_active: true,
        program_id: null,
        width: 112,
        height: 176,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        gap_bottom: 0,
        gap_right: 0,
        student_photo_position: "",
        student_photo_position_top: 0,
        student_photo_position_left: 0,
        student_photo_width: 50,
        student_photo_height: 50,
        student_id_position: "",
        student_id_position_top: 0,
        student_id_position_left: 0,
        student_name_position_top: 0,
        student_name_position_left: 0,
        program_name_position: "",
        program_name_position_top: 0,
        program_name_position_left: 0,
        parent_name_position: "",
        parent_name_position_top: 0,
        parent_name_position_left: 0,
        mobile_no_position: "",
        mobile_no_position_top: 0,
        mobile_no_position_left: 0,
        barcode_position: "",
        barcode_position_top: 0,
        barcode_position_left: 0,
        address_position: "",
        address_position_top: 0,
        address_position_left: 0,
        registration_no_position: "",
        registration_no_position_top: 0,
        registration_no_position_left: 0,
        date_of_birth_position: "",
        date_of_birth_position_top: 0,
        date_of_birth_position_left: 0,
        blood_group_position: "",
        blood_group_position_top: 0,
        blood_group_position_left: 0,
        job_position: "",
        job_position_top: 0,
        job_position_left: 0,
        principal_sign_position: "",
        principal_sign_position_top: 0,
        principal_sign_position_left: 0,
        vaildity_date_position: "",
        vaildity_date_position_top: 0,
        vaildity_date_position_left: 0,
        company_name_position_top: 0,
        company_name_position_left: 0,
        company_phone_position_top: 0,
        company_phone_position_left: 0,
        company_address_position_top: 0,
        company_address_position_left: 0,
        company_website_position_top: 0,
        company_website_position_left: 0,
        company_email_position_top: 0,
        company_email_position_left: 0,
        qr_code_position_top: 0,
        qr_code_position_left: 0,
        free_text_one_position_top: 0,
        free_text_one_position_left: 0,
        free_text_two_position_top: 0,
        free_text_two_position_left: 0,
        free_text_three_position_top: 0,
        free_text_three_position_left: 0,
      }
    }
  },
};
</script>

<style scoped>
    .col-md-2 {
      padding: 5px !important;
    }
    .col-12 {
      padding: 5px !important;
    }

    .bgimg {
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

</style>
