<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-6">
          <h4>Manage id card templates</h4>
        </div>
        <div class="col-6 text-right" v-if="!loading">
          <button class="btn btn-primary" @click="addTemplate">Add new template</button>
        </div>
      </div>
      <div class="row" v-if="!loading">
        <div class="col-12">
          <table class="table">
            <thead>
              <tr class="px-3">
                <th class="px-3 wrap-column">File name</th>
                <th class="px-3 wrap-column">Default template</th>
                <th class="px-3 wrap-column">Program</th>
                <th class="px-3 wrap-column">Action</th>
              </tr>
            </thead>
            <tbody v-if="templates.length > 0">
              <tr v-for="(template, index) of templates" :key="index">
                <td class="px-3 wrap-column">
                  <a class="cursor-pointer" @click="editTemplate(template)">
                    {{ template.file_name }}
                  </a>
                  <i
                    class="fas fa-circle"
                    :class="template.is_active ? 'dot-active' : 'dot-inactive'"
                  ></i>
                </td>
                <td class="px-3 wrap-column">
                  <span
                    :class="
                      template.is_default ? 'badge badge-success' : 'badge badge-danger'
                    "
                    >{{ template.is_default ? "YES" : "NO" }}</span
                  >
                </td>

                <td class="px-3 wrap-column">
                  <span >{{
                    template.program.short_title
                  }}</span>
                </td>
                <td>
                  <b-dropdown
                    size="sm"
                    variant="link"
                    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                    no-caret
                    right
                    no-flip
                  >
                    <template v-slot:button-content>
                      <slot>
                        <span>
                          <i class="flaticon-more-1"></i> </span
                      ></slot>
                    </template>
                    <!--begin::Navigation-->
                    <div class="navi navi-hover">
                      <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('template', 'edit')">
                        <a href="#" class="navi-link" @click="setAsDefault(template)">
                          <span class="navi-icon">
                            <i class="fas fa-eye"></i>
                          </span>
                          <span class="navi-text"> Set as default</span>
                        </a>
                      </b-dropdown-text>
                      <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('template', 'preview')">
                        <a href="#" class="navi-link" @click="previewIdCard(template.id)">
                          <span class="navi-icon">
                            <i class="fas fa-eye"></i>
                          </span>
                          <span class="navi-text"> Preview</span>
                        </a>
                      </b-dropdown-text>
                      <b-dropdown-text tag="div" class="navi-item"  v-if="checkIsAccessible('template', 'edit')">
                        <a href="#" class="navi-link" @click="editTemplate(template)">
                          <span class="navi-icon">
                            <i class="flaticon-edit"></i>
                          </span>
                          <span class="navi-text"> Edit</span>
                        </a>
                      </b-dropdown-text>
                      <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('template', 'edit')">
                        <a
                          href="#"
                          class="navi-link"
                          @click="deleteTemplate(template.id)"
                        >
                          <span class="navi-icon">
                            <i class="fas fa-trash"></i>
                          </span>
                          <span class="navi-text"> Delete</span>
                        </a>
                      </b-dropdown-text>
                    </div>
                    <!--end::Navigation-->
                  </b-dropdown>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td class="text-center" colspan="8">No templates available yet</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-md-12">
          <v-skeleton-loader v-bind="loading" type="table"></v-skeleton-loader>
        </div>
      </div>
    </div>

    <old-idcard-template
        v-if="domainName == 'kist'"
        ref="old-template"
        :program="program"
        @refresh="getTemplates"
    ></old-idcard-template>

    <create-and-update
       v-else
       ref="template"
       :program="program"
       @refresh="getTemplates"
    ></create-and-update>
  </div>
</template>
<script>
import CreateAndUpdate from "./CreateAndUpdate";
import TemplatesService from "@/core/services/templates/TemplatesService";
import { API_URL } from "@/core/config";
import OldIdcardTemplate from "@/view/pages/setting/templates/OldIdcardTemplate.vue";

const templatesService = new TemplatesService();
export default {
  components: {
    OldIdcardTemplate,
    CreateAndUpdate,
  },

  data() {
    return {
      loading: true,
      templates: [],
      program: {},
      search: {
        type: "id_card",
      },
    };
  },
  mounted() {
    this.getTemplates();
  },
  computed: {
    domainName() {
      let origin = window.location.origin;
      if(origin.includes('kist'))
        return 'kist';
      return 'default';
    }
  },
  methods: {
    getTemplates() {
      this.loading = true
      templatesService.paginate(this.search.type).then((response) => {
        this.templates = response.data.data;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },

    addTemplate() {
      if(this.domainName == 'kist') {
        this.$refs["old-template"].showModal();
      } else {
        this.$refs["template"].showModal();
      }
    },

    editTemplate(template) {
      if(this.domainName == 'kist') {
        this.$refs["old-template"].showModal(template);
      } else {
        this.$refs["template"].showModal(template);
      }
    },

    setAsDefault(template) {
      if(!template.is_default) {
        templatesService.setAsDefault(template.id, template.program_id).then((response) => {
          if(response.data.status == "OK") {
            this.$snotify.success("Update successfully !!");
            this.getTemplates();
          }
        }).catch(() => {})
      } else {
        this.$snotify.error("It is already default !!");
      }
    },

    deleteTemplate(id) {
      this.$confirm({
        message: `Are you sure you want to remove this item?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            templatesService.delete(id).then(() => {
              this.$snotify.success("Item deleted");
              this.getTemplates();
            });
          }
        },
      });
    },
    previewIdCard(templateId) {
      window.open(API_URL + `user/templates/${templateId}/preview/id-cards`, "_blank");
    },
  },
};
</script>
