<template>
  <v-app>
    <v-dialog fullscreen
              v-model="dialog">
      <v-card>

        <v-toolbar dark>
          <v-card-title class="headline">
            {{`${edit?'Update':'Add'} ID card template`}}
            <hr>
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon  @click="hideModal">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <div class="row">

            <div class="col-3">
              <v-text-field
                  v-model="template.file_name"
                  outlined
                  dense
                  label="Title">

              </v-text-field>
              <span class="text-danger" v-if="$v.template.file_name.$error">File Name is required</span>
            </div>

            <div class="col-3">
              <v-file-input
                  @change="Preview_image"
                  v-model="template.bg_image"
                  label="Background image"
                  prepend-inner-icon="mdi-camera"
                  prepend-icon=""
                  @click:clear="previewImage = null"
                  outlined
                  dense
              ></v-file-input>
            </div>

            <div class="col-3">
              <v-select
                  :items="programs"
                  v-model="template.program_id"
                  outlined
                  item-text="title"
                  item-value="id"
                  dense
                  label="Program"
              ></v-select>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              <div class="col-12">
                <v-select
                    v-model="settings.template_for"
                    :items="['Student','Employee']"
                    outlined
                    dense
                    label="Id Card For"
                >
                </v-select>

              </div>

              <div class="col-12">
                <v-radio-group v-if="template" v-model="settings.orientation">
                  <template v-slot:label>
                    <div>Select <strong>orientation</strong></div>
                  </template>
                  <v-radio value="portrait">
                    <template v-slot:label>
                      <div><strong class="success--text">Portrait</strong></div>
                    </template>
                  </v-radio>
                  <v-radio value="landscape">
                    <template v-slot:label>
                      <div><strong class="primary--text">Landscape</strong></div>
                    </template>
                  </v-radio>
                </v-radio-group>

              </div>

              <div class="col-12">
                <label for="">Options</label>
                <v-checkbox
                    v-model="selected_options"
                    label="Blood group"
                    value="blood_group"
                ></v-checkbox>
                <v-checkbox
                    v-model="selected_options"
                    label="Job position"
                    value="job_position"
                ></v-checkbox>
                <v-checkbox
                    v-model="selected_options"
                    label="Mobile No"
                    value="mobile_no"
                ></v-checkbox>
                <v-checkbox
                    v-model="selected_options"
                    label="Address"
                    value="address"
                ></v-checkbox>
                <v-checkbox
                    v-model="selected_options"
                    label="Bar code"
                    value="bar_code"
                ></v-checkbox>

              </div>

              <div class="col-12">
                <v-switch
                    v-model="template.is_default"
                    outlined
                    dense
                    label="Default"
                >
                </v-switch>

              </div>

              <div class="col-12">
                <v-switch
                    v-model="template.is_active"
                    outlined
                    dense
                    label="Status"
                >
                </v-switch>

              </div>

              <div class="col-12 ">
                <v-btn depressed @click="hideModal">Cancel</v-btn>
                <v-btn class="ml-2 text-white btn btn-primary" depressed color="blue" :loading="isBusy"
                       @click="createAndUpdate">Save
                </v-btn>
              </div>
            </div>
            <div class="col-3" v-if="previewImage">
              <h5>Id card preview</h5>
              <v-card
                  v-if="previewImage && settings.orientation=='portrait'"
                  raised
                  class="mx-auto my-12"
                  max-width="374"
              >
                <template slot="progress">
                  <v-progress-linear
                      color="deep-purple"
                      height="10"
                      indeterminate
                  ></v-progress-linear>
                </template>

                <v-img
                    content-class="test"
                    :src="previewImage"
                >
                  <div class="w-75 ">
                    <p><strong>Full Name</strong></p>
                    <p><strong>Id No.</strong> XXX</p>
                    <p v-if="selected_options.includes('mobile_no')"> 980XXXXXX</p>
                    <p v-if="selected_options.includes('address')"> kathmandu, Nepal</p>
                    <p v-if="selected_options.includes('blood_group')"> B+</p>
                    <p v-if="selected_options.includes('job_position')">It Administrator Officer</p>
                    <p><strong>Validity</strong> XXX</p>

                    <div class="row">
                      <v-sheet
                          v-if="selected_options.includes('bar_code')"
                          class="col-6 mx-auto p-2"
                          color=" grey lighten-3"
                          height="20"
                      >
                        Barcode
                      </v-sheet>
                      <v-sheet class="col-6 mx-auto p-2" color="grey lighten-3" height="20">
                        Signature
                      </v-sheet>
                    </div>
                  </div>
                </v-img>


              </v-card>

              <v-card
                  v-if="previewImage && settings.orientation=='landscape'"
                  raised
                  class="mx-auto my-12"
                  max-width="374"
              >
                <template slot="progress">
                  <v-progress-linear
                      color="deep-purple"
                      height="10"
                      indeterminate
                  ></v-progress-linear>
                </template>

                <v-img
                    content-class="landscape-mode"
                    :src="previewImage"
                >
                  <div class="landscape-mode-wrapper">
                    <div class="row row--dense">
                      <div class="col-3">
                        <figure>
                          <img src="" alt="">
                        </figure>
                      </div>
                      <div class="col-9">
                        <article>
                          <p><strong>Full Name</strong></p>
                          <p><strong>Id No.</strong> XXX</p>
                          <p v-if="selected_options.includes('mobile_no')"> 980XXXXXX</p>
                          <p v-if="selected_options.includes('address')"> kathmandu, Nepal</p>
                          <p v-if="selected_options.includes('blood_group')"> B+</p>
                          <p v-if="selected_options.includes('job_position')">It Administrator
                            Officer</p>
                          <p><strong>Validity</strong> XXX</p>
                        </article>
                      </div>

                    </div>

                    <div class="row row--dense">
                      <v-sheet
                          class="col-6 mx-auto p-2"
                          color=" grey lighten-3"
                          min-height="20"
                      >
                        Barcode
                      </v-sheet>
                      <v-sheet class="col-6 mx-auto p-2" color="grey lighten-3" min-height="20">
                        Signature
                      </v-sheet>
                    </div>
                  </div>
                </v-img>


              </v-card>
            </div>
            <div class="col-7" v-if="previewImage">
              <h5> Print Preview</h5>
              <v-card outlined>
                <v-card-text>
                  <div class="row">
                    <div :class="settings.orientation=='portrait' ? 'col-3' : 'col-6'" v-for="card in 6"
                         :key="card">
                      <v-card
                          v-if="previewImage && settings.orientation=='portrait'"
                          raised
                          class="mx-auto my-12"
                          max-width="374"
                      >
                        <template slot="progress">
                          <v-progress-linear
                              color="deep-purple"
                              height="10"
                              indeterminate
                          ></v-progress-linear>
                        </template>

                        <v-img
                            content-class="test"
                            :src="previewImage"
                        >
                          <div class="w-75 ">
                            <p><strong>Full Name</strong></p>
                            <p><strong>Id No.</strong> XXX</p>
                            <p v-if="selected_options.includes('mobile_no')"> 980XXXXXX</p>
                            <p v-if="selected_options.includes('address')"> kathmandu, Nepal</p>
                            <p v-if="selected_options.includes('blood_group')"> B+</p>
                            <p v-if="selected_options.includes('job_position')">It Administrator
                              Officer</p>
                            <p><strong>Validity</strong> XXX</p>

                            <div class="row">
                              <v-sheet
                                  class="col-6 mx-auto p-2"
                                  color=" grey lighten-3"
                                  height="20"
                              >
                                Barcode
                              </v-sheet>
                              <v-sheet class="col-6 mx-auto p-2" color="grey lighten-3"
                                       height="20">
                                Signature
                              </v-sheet>
                            </div>
                          </div>
                        </v-img>


                      </v-card>
                      <v-card
                          v-if="previewImage && settings.orientation=='landscape'"
                          raised
                          class="mx-auto my-12"
                          max-width="374"
                      >
                        <template slot="progress">
                          <v-progress-linear
                              color="deep-purple"
                              height="10"
                              indeterminate
                          ></v-progress-linear>
                        </template>

                        <v-img
                            content-class="landscape-mode"
                            aspect-ratio="16/9"
                            cover
                            :src="previewImage"
                        >
                          <div class="landscape-mode-wrapper">
                            <div class="row row--dense">
                              <div class="col-3">
                                <figure>
                                  <img src="" alt="">
                                </figure>
                              </div>
                              <div class="col-9">
                                <article>
                                  <p><strong>Full Name</strong></p>
                                  <p><strong>Id No.</strong> XXX</p>
                                  <p v-if="selected_options.includes('mobile_no')">
                                    980XXXXXX</p>
                                  <p v-if="selected_options.includes('address')">
                                    kathmandu, Nepal</p>
                                  <p v-if="selected_options.includes('blood_group')">
                                    B+</p>
                                  <p v-if="selected_options.includes('job_position')">It
                                    Administrator Officer</p>
                                  <p><strong>Validity</strong> XXX</p>
                                </article>
                              </div>

                            </div>

                            <div class="row row--dense">
                              <v-sheet
                                  class="col-6 mx-auto p-2"
                                  color=" grey lighten-3"
                                  min-height="20"
                              >
                                Barcode
                              </v-sheet>
                              <v-sheet class="col-6 mx-auto p-2" color="grey lighten-3"
                                       min-height="20">
                                Signature
                              </v-sheet>
                            </div>
                          </div>
                        </v-img>


                      </v-card>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </div>
        </v-card-text>
      </v-card>

    </v-dialog>
  </v-app>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import ProgramService from "@/core/services/level/program/ProgramService";
import TemplatesService from "@/core/services/templates/TemplatesService";

const programService = new ProgramService();

const templatesService = new TemplatesService();
export default {
  name: "OldIdcardTemplate",
  validations: {
    template: {
      file_name: {required},
    }
  },
  data() {
    return {
      dialog: false,
      menu: false,
      isBusy: false,
      menu2: false,
      edit: false,
      selected_options: ["mobile_no", "address",'bar_code'],
      levels: [],
      programs: [],
      academicYears: [],
      gradesLevels: [],
      setting: null,
      previewImage: null,
      settings: {
        orientation: 'portrait',
        options: [],
        template_for: 'Student',
      },
      template: {
        file_name: null,
        path: null,
        type: 'id_card',
        bg_image: null,
        is_default: null,
        is_active: true,
        program_id: null,
      }
    };
  },
  computed: {

    programId() {
      return this.$route.params.id ? this.$route.params.id : false;
    }
  },

  watch: {
    settings(newValue, oldValue) {
      deep: true,
          handler()
      {
        console.log(newValue)
        this.template.settings = newValue;
      }
    }
  },

  methods: {
    getProgram() {
      programService.all().then(response => {
        this.programs = response.data
      });
    },

    showModal(template = {}) {
      if (template.id) {
        this.template = template;
        // console.log(this.template.image_pdf_path)
        if(this.template.image_pdf_path)
          this.previewImage = this.template.image_pdf_path.real;
        this.edit = true
      } else {
        this.edit = false;
        this.resetForm();
      }
      this.getProgram()
      this.dialog = true;
    },
    hideModal() {
      this.dialog = false;
      this.resetForm()
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.dialog = false;
    },
    createAndUpdate() {
      // this.template.academic_year_id = this.setting.academic_year_id;
      delete this.template.image_path;
      delete this.template.program;
      this.template.settings  = {};
      this.template.settings = this.settings;
      this.template.settings.options = this.selected_options;
      this.template.settings = JSON.stringify(this.template.settings);
      this.$v.$touch();
      if (this.$v.template.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        let fd = this.generateFd();
        if (this.edit) {
          this.updateTemplate(fd);
        } else {
          this.createTemplate(fd);
        }
      }
    },
    createTemplate(fd) {
      this.isBusy = true;
      templatesService.store(fd).then(response => {
        this.hideModal()
        this.isBusy = false;
        this.$snotify.success("Information added");
        this.$emit("refresh");
      });
    },
    generateFd() {
      let fd = new FormData();
      for (let key in this.template) {
        if (
            key == "bg_image" &&
            this.template["bg_image"] &&
            this.template["bg_image"] != null
        ) {
          if (typeof this.template["bg_image"] == "object") {
            fd.append("bg_image", this.template[key]);
          }
        } else if (key == "is_default") {
          fd.append("is_default", this.template.is_default ? 1 : 0);
        } else if (key == "is_active") {
          fd.append("is_active", this.template.is_active ? 1 : 0);
        } else {
          if (key != "id" && this.template[key]) {
            fd.append(key, this.template[key]);
          }
        }
      }
      return fd;
    },
    //preview Image While select
    Preview_image() {
      this.previewImage = URL.createObjectURL(this.template.bg_image)
    },

    updateTemplate(fd) {
      this.isBusy = true;
      templatesService.update(this.template.id, fd).then(response => {
        this.hideModal()
        this.isBusy = false;
        this.$snotify.success("Information updated");
        this.$emit("refresh");
      });
    },
    resetForm() {
      this.edit = false
      this.$v.$reset()
      this.previewImage = null;
      this.template = {
        file_name: null,
        path: null,
        type: 'id_card',
        bg_image: null,
        is_default: null,
        is_active: true,
        program_id: null,
      }
    }
  },
};
</script>

<style>
.test, .landscape-mode {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}

.test-preview {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
}

.text-preview p {
  margin-top: 0;
  margin-bottom: .2rem !important;
}

.landscape-mode {
  padding: 0;
}

.landscape-mode-wrapper {
  width: 100%;
  height: 100%;
  background-color: #eee;
  padding: 60px 10px 10px;
  margin-left: -10px;
  margin-right: -10px;
}

.landscape-mode .row--dense {
  margin-left: -8px;
  margin-right: -8px;
}

.landscape-mode .row--dense > * {
  padding-left: 8px;
  padding-right: 8px;
}

.landscape-mode figure {
  width: 100%;
  height: 80px;
}

.landscape-mode figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #ccc;
}

.landscape-mode article p {
  margin: 0 0 2px;
  font-size: 12px;
  line-height: 1.5;
}
</style>
