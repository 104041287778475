<template>
  <v-app>
    <v-card flat>
      <v-card-text>
        <div class="row">
          <div class="col-12 float-right">
            <h3>Library setting</h3>
          </div>
          <div class="col-12 form-group">
            <v-switch v-model="setting.enable_accession_no" label="Enable accession"></v-switch>
          </div>
          <div class="col-12 form-group">
            <v-file-input outlined dense v-model="setting.library_background_image" label="Background image"></v-file-input>

            <img
                :src="setting.library_background_image_path['thumb']"
                alt
                class="mt-5"
                style="height:200px"
                v-if="setting.library_background_image_path && setting.library_background_image_path['thumb']"
            />
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-app>
</template>

<script>
export default {
  name: "Library",
  props: ['setting']
}
</script>

<style scoped>

</style>